import { Injectable } from '@angular/core';
import { DynamicAdvisingOffer } from 'ds-api';

@Injectable({
  providedIn: 'root'
})
export class DynamicCounselingService {
  /**
   * Change the order. Highest offer value first.
   * @param sigmaResponse
   */
  public getPrioritizeCounseling(sigmaResponse: DynamicAdvisingOffer[]) {
    return this.onlyUniqueTopics(sigmaResponse).sort((a, b) => {
      if (a.offerSeverity > b.offerSeverity) {
        return a.offerSeverity > b.offerSeverity ? -1 : 1;
      } else if (this.nbaValueFurthestFrom0(a, b)) {
        return -1;
      }
      return 0;
    });
  }

  /**
   * Remove topics so only unique topics exists with the highest offerValue.
   * @param sigmaResponse
   */
  private onlyUniqueTopics(
    sigmaResponse: DynamicAdvisingOffer[]
  ): Array<DynamicAdvisingOffer> {
    const onlyUniqueTopics: Array<DynamicAdvisingOffer> = [];
    sigmaResponse.forEach(sigma => {
      let topicIndex = -1;
      onlyUniqueTopics.forEach((uniqueTopic, index) => {
        if (
          sigma.offerRecommendationCategory ===
            uniqueTopic.offerRecommendationCategory ||
          this.mergedPolicies(sigma, uniqueTopic)
        ) {
          topicIndex = index;
        }
      });
      if (topicIndex > -1) {
        const foundTopic = onlyUniqueTopics[topicIndex];
        if (this.nbaValueFurthestFrom0(sigma, foundTopic)) {
          onlyUniqueTopics[topicIndex] = sigma;
        }
      } else {
        onlyUniqueTopics.push(sigma);
      }
    });
    return onlyUniqueTopics;
  }

  private nbaValueFurthestFrom0(
    sigma: DynamicAdvisingOffer,
    foundTopic: DynamicAdvisingOffer
  ): boolean {
    let sigmaValue = sigma.offerNbaValue;
    if (sigmaValue < 0) {
      sigmaValue = 0 - sigma.offerNbaValue;
    }
    let foundTopicValue = foundTopic.offerNbaValue;
    if (foundTopicValue < 0) {
      foundTopicValue = 0 - foundTopic.offerNbaValue;
    }
    return sigmaValue > foundTopicValue;
  }

  private mergedPolicies(
    sigma: DynamicAdvisingOffer,
    unique: DynamicAdvisingOffer
  ): boolean {
    if (
      sigma.offerRecommendationCategory === 'GIPP_TO_PLUS' ||
      sigma.offerRecommendationCategory === 'MERGE_POLICIES' ||
      sigma.offerRecommendationCategory === 'EXTERNAL_FUNDS'
    ) {
      if (
        unique.offerRecommendationCategory === 'GIPP_TO_PLUS' ||
        unique.offerRecommendationCategory === 'MERGE_POLICIES' ||
        unique.offerRecommendationCategory === 'EXTERNAL_FUNDS'
      ) {
        return true;
      }
    }
    return false;
  }
}
