import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { DynamicAdvisingOfferidType } from 'ds-api';

@Component({
  selector: 'mitpfa-pension-check-card-viewer',
  templateUrl: './pension-check-card-viewer.component.html',
  styleUrls: ['./pension-check-card-viewer.component.scss']
})
export class PensionCheckCardViewerComponent implements OnChanges {
  @Input() selectedCardId: DynamicAdvisingOfferidType;
  @Input() selectedCardIdList: DynamicAdvisingOfferidType[];
  @Output() close = new EventEmitter<void>();

  public showInformationCard = false;

  closeViewer() {
    this.close.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.selectedCardId &&
      changes.selectedCardId !== changes.selectedCardId.previousValue
    ) {
      this.showInformationCard = this.showPensionCheckInformation();
    }
  }

  scrollToTop() {
    document.querySelector('#closeviewer').scrollIntoView();
  }

  private showPensionCheckInformation(): boolean {
    return (
      this.selectedCardId === 'RETRIEVE_PENSION_INFO_REPORT' ||
      this.selectedCardId === 'ENTER_DEBT' ||
      this.selectedCardId === 'ENTER_WEALTH' ||
      this.selectedCardId === 'ENTER_DEPENDENTS' ||
      this.selectedCardId === 'ENTER_MARITAL_STATUS' ||
      this.selectedCardId === 'ENTER_PARTNER_SALARY'
    );
  }
}
