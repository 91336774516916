import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject
} from '@angular/core';
import { Router } from '@angular/router';
import { PensionPlanApiService } from '@pfa/api';
import { combineLatest } from 'rxjs';
import { MiscTrackingService } from '@pfa/core';
import { Utils } from '@pfa/utils';
import { DashboardMessageService } from '../../dashboard-message.service';
import {
  OpsparingsplanDetaljer,
  PensionsKundeGenerelleData,
  PensionsplanTile,
  SavingsPlanStore
} from 'ds-api';

@Component({
  selector: 'mitpfa-dashboard-savings-payouts',
  templateUrl: './dashboard-savings-payouts.component.html',
  styleUrls: ['./dashboard-savings-payouts.component.scss']
})
export class DashboardSavingsPayoutsComponent implements OnInit {
  private readonly router: Router = inject(Router);
  private readonly savingsplanStore: SavingsPlanStore =
    inject(SavingsPlanStore);
  private readonly pensionPlanApiService: PensionPlanApiService = inject(
    PensionPlanApiService
  );
  private readonly dashboardMessageService: DashboardMessageService = inject(
    DashboardMessageService
  );
  private readonly trackingService: MiscTrackingService =
    inject(MiscTrackingService);

  @Output() contentLoaded = new EventEmitter<void>();
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Input() payoutsStarted: boolean;

  public pensionAge: number;
  public plannedPensionAge: number;
  public pensionEstimate: number;
  public monthlyPayoutFormatted: string;
  public payoutsData: boolean;
  public initialized: boolean;
  public serviceError: boolean;
  public policiesWithError: boolean;
  public allPoliciesWithError: boolean;

  public ngOnInit(): void {
    function roundToHundreds(value: number): number {
      return 100 * Math.floor(value / 100);
    }

    combineLatest({
      pensionPlan: this.pensionPlanApiService.getPensionPlanTile(),
      savings: this.savingsplanStore.opsparingsplanGet()
    }).subscribe({
      next: result => {
        const pureRisk = result.pensionPlan.kunForsikringer;
        const savings: OpsparingsplanDetaljer = result.savings;
        const pensionPlan: PensionsplanTile = result.pensionPlan;
        const monthlyPayout: number = roundToHundreds(
          savings.minOpPlan.maanedsloenEfterSkatVedPensionNuvaerende
        );
        this.monthlyPayoutFormatted = Utils.formatNumber(monthlyPayout);
        this.pensionAge = savings.pensionsalder;
        this.plannedPensionAge =
          savings.minOpPlan.minOpsparingsplan.pensionsalder;
        this.pensionEstimate = savings.minOpPlan.pensionstalNuvaerende;
        this.payoutsData = this.hasPayoutsData(savings, pureRisk);
        this.policiesWithError = pensionPlan.fejledePolicenumre.length > 0;
        this.allPoliciesWithError =
          savings.opsparingsformPolicer?.length !== 0 &&
          savings.opsparingsformPolicer?.length ===
            pensionPlan.fejledePolicenumre.length;

        this.announcePayoutsData();
      },
      error: () => {
        this.serviceError = true;
        this.initialized = true;
        this.contentLoaded.emit();
      }
    });
  }

  private announcePayoutsData() {
    this.initialized = true;
    this.dashboardMessageService.announcePayoutsData(this.payoutsData);
  }

  private hasPayoutsData(
    savings: OpsparingsplanDetaljer,
    pureRisk: boolean
  ): boolean {
    return (
      savings.alder < 60 &&
      (savings.loen ?? 0) > 0 &&
      savings.pensionstal >= 0 &&
      savings.pensionstal <= 500 &&
      !pureRisk
    );
  }

  goToPensionPlan() {
    this.trackingService.trackNavigationCard(
      'Savings section',
      'Savings plan',
      '/pensionstal'
    );
    this.router.navigate(['/pensionstal']);
  }

  public stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }
}
