import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  Event,
  NavigationEnd,
  NavigationStart,
  Router
} from '@angular/router';
import {
  LanguageService,
  LightUtils,
  LoginService,
  RoutingService
} from '@mitpfa/shared';
import { map } from 'rxjs/operators';
import { MobileBridgeService } from './mobile-bridge/mobile-bridge.service';
import { LoginMethod } from './logon/logon.model';
import { CustomerprofileStore } from 'ds-api';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, of } from 'rxjs';
import { SessionTimeoutService } from '@pfa/core';

@Component({
  selector: 'mitpfa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private readonly mobileBridgeService: MobileBridgeService =
    inject(MobileBridgeService);
  private readonly router: Router = inject(Router);
  private readonly customerprofileStore: CustomerprofileStore =
    inject(CustomerprofileStore);
  private readonly loginService: LoginService = inject(LoginService);
  private readonly routingService: RoutingService = inject(RoutingService);
  private readonly languageService: LanguageService = inject(LanguageService);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  public loggedIn$: Observable<boolean>;
  public userAllowedInMitPFA?: boolean;
  public appInitialized: boolean;
  public hideHeader = true;
  public hideFooter = true;
  public hideChat = true;
  public hideAdvisorMenu = false;
  public isOldOsVersion = false;
  private readonly sessionTimeoutService: SessionTimeoutService = inject(
    SessionTimeoutService
  );

  ngOnInit(): void {
    const userAgent = window.navigator.userAgent;
    const osVersion = /iP(hone|ad|od) OS ([1-9]*)/i.exec(userAgent)?.[2] || NaN;
    const sVersion = /Safari\/(\d+(?:\.\d+)+)/i.exec(userAgent)?.[1] || NaN;

    if ((Number(osVersion) || Number(sVersion)) < 15) {
      this.isOldOsVersion = true;
      return;
    }

    this.loggedIn$ = this.loginService.loggedIn$;

    // if you refresh when you are login
    this.loginService.loggedIn$.pipe().subscribe({
      next: login => {
        if (login) {
          //Need to have this here for now, because of light
          this.customerprofileStore
            .customerprofileGet()
            .pipe(
              takeUntilDestroyed(this.destroyRef),
              map(profile => {
                if (profile) {
                  this.userAllowedInMitPFA =
                    !LightUtils.isLightProfile(profile);
                }
              })
            )
            .subscribe({
              next: () => {
                this.appInitialized = true;
                this.sessionTimeoutService.initiateTimer();
              },
              error: e => {
                this.appInitialized = true;
                this.loggedIn$ = of(null);
                throw new Error(e.message);
              }
            });
        }
      }
    });

    this.handleRouterEvents();
    this.handleAuthenticationEvent();
  }

  private handleAuthenticationEvent(): void {
    this.loginService.authenticationEvent
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((loginMethod: LoginMethod) => {
        if (loginMethod === LoginMethod.MIT_ID) {
          this.mobileBridgeService.authenticationCompleted();
        }
      });
  }

  private handleRouterEvents(): void {
    this.router.events
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(event => {
        this.handleNavigationStart(event);
        this.handleNavigationEnd(event);
      });
  }

  private handleNavigationStart(event: Event): void {
    if (event instanceof NavigationStart) {
      // cant the router to this? then this can be deleted
      this.routingService.setPreviousUrl(this.router.url);
    }
  }

  private handleNavigationEnd(event: Event): void {
    if (event instanceof NavigationEnd) {
      this.hideHeader =
        this.activatedRoute.firstChild.snapshot.data['hideHeader'];
      this.hideFooter =
        this.activatedRoute.firstChild.snapshot.data['hideFooter'];
      this.hideChat = this.activatedRoute.firstChild.snapshot.data['hideChat'];
      this.hideAdvisorMenu =
        this.activatedRoute.firstChild.snapshot.data['hideAdvisorMenu'];
    }
  }

  setLanguage(lang: 'da' | 'en'): void {
    this.languageService.setLanguage(lang);
  }
}
