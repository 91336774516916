<div class="text--container bottom-space">
  <div class="header text--manchet">
    <div *ngIf="!changeHeadline" [coContent]="'DL.VK01.C24'"></div>
    <div *ngIf="changeHeadline" [coContent]="'DL.VK01.C38'"></div>
  </div>
</div>

<mitpfa-onboarding-step-animation
  [firstCard]="firstCard"
  [secondCard]="secondCard"
  [extraCards]="2"
>
  <ng-template #firstCard>
    <mitpfa-svg-information class="mobile--step1"></mitpfa-svg-information>
    <div class="wrapper">
      <div>
        <div class="headline">
          <div class="text--grey text--bold" [coContent]="'DL.VK01.C25'"></div>
          <div class="number--icon disabled">1</div>
        </div>
        <div class="text--grey body-text" [coContent]="'DL.VK01.C26'"></div>
        <button
          mat-raised-button
          class="mat-focus-indicator mat-button-bas move-on"
          disabled="true"
          [coContent]="'DL.VK01.C27'"
        ></button>
      </div>
      <div class="container--desktop">
        <mitpfa-svg-information class="image--desktop"></mitpfa-svg-information>
      </div>
    </div>
  </ng-template>
  <ng-template #secondCard>
    <mitpfa-svg-savings class="image--mobile--step2"></mitpfa-svg-savings>
    <div class="wrapper">
      <div>
        <div class="headline">
          <div
            class="text--color-primary text--bold"
            [coContent]="'DL.VK01.C39'"
          ></div>
          <div class="number--icon">2</div>
        </div>
        <div class="body-text" [coContent]="'DL.VK01.C40'"></div>
        <button
          mat-raised-button
          class="mat-focus-indicator mat-button-bas move-on"
          (click)="next()"
          [coContent]="'DL.VK01.C41'"
          data-test-id="step-finances-btn"
        ></button>
      </div>
      <div class="container--desktop">
        <mitpfa-svg-savings class="image--desktop"></mitpfa-svg-savings>
      </div>
    </div>
  </ng-template>
</mitpfa-onboarding-step-animation>
