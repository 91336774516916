import { Injectable } from '@angular/core';
import { DynamicAdvisingOffer } from 'ds-api';
import { MiscTrackingService, TrackingName } from '@pfa/core';
import { NextBestActionService } from '@mitpfa/shared';

export interface DynamicAdvisingOfferExtended extends DynamicAdvisingOffer {
  offerTrackingIndex: number;
}

@Injectable({
  providedIn: 'root'
})
export class PensionCheckService {
  constructor(
    private readonly trackingService: MiscTrackingService,
    private readonly nextBestActionService: NextBestActionService
  ) {}

  public trackNbaOffersList(
    trackingName: TrackingName,
    nbaId: string,
    offers: DynamicAdvisingOffer[]
  ) {
    this.trackingService.trackNbaOffersList(
      trackingName,
      nbaId,
      this.nextBestActionService.buildNbaOfferTrackingList(offers)
    );
  }
}
