import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { PensionsKundeGenerelleData } from 'ds-api';
import { UntypedFormGroup } from '@angular/forms';
import { GlobalWorkingService } from '@pfaform';

@Component({
  selector: 'mitpfa-onboarding-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  private readonly globalWorkingService: GlobalWorkingService =
    inject(GlobalWorkingService);

  @Output() goTo = new EventEmitter();
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Input() isUpdateError: boolean;
  @Output() form = new EventEmitter<UntypedFormGroup>();

  public handleContactDetailsUpdate(): void {
    this.globalWorkingService.hide();
    this.goTo.emit();
  }

  public setForm($event: UntypedFormGroup): void {
    this.form.emit($event);
  }
}
