import { Injectable } from '@angular/core';
import { InvesteringPolice, PfaInvesterer, PfaValgfri } from 'ds-api';

interface ClimateFootprintInput {
  pfaPlusHigh: number;
  pfaPlusLow: number;
  savings: number;
}

export enum TrackingEventNames {
  CARBON_FOOTPRINT = 'carbon footprint'
}

@Injectable({
  providedIn: 'root'
})
export class ClimateFootprintService {
  filterPolicies(policies: InvesteringPolice[]): InvesteringPolice[] {
    const temp = [] as InvesteringPolice[];

    policies.forEach(policy => {
      let high = 0;
      let low = 0;

      if (policy.pfaInvesterer?.harPfaInvesterer) {
        high = policy.pfaInvesterer?.hoejFaktiskTotalFordelingProcent ?? 0;
        low = policy.pfaInvesterer?.lavFaktiskTotalFordelingProcent ?? 0;
      } else if (policy.pfaValgfri?.harPfaValgfri) {
        high = policy.pfaValgfri?.hoejFaktiskTotalFordelingProcent ?? 0;
        low = policy.pfaValgfri?.lavFaktiskTotalFordelingProcent ?? 0;
      }

      const sum = high + low;

      if (
        sum === 100 &&
        policy.klimaPlusAndel >= 0 &&
        policy.klimaPlusAndel <= 100
      ) {
        temp.push(policy);
      }
    });

    return temp.filter(
      policy =>
        policy.type === 'Marked' &&
        policy.duInvesterer?.simpelOpsparing?.origDuInvesterer !== 100 &&
        (policy.pfaInvesterer?.harPfaInvesterer ||
          policy.pfaValgfri?.harPfaValgfri)
    );
  }

  checkInputValues(
    pfaInvest: PfaInvesterer,
    pfaOptional: PfaValgfri
  ): ClimateFootprintInput {
    const obj = {
      pfaPlusHigh: 0,
      pfaPlusLow: 0,
      savings: 0
    } as ClimateFootprintInput;

    if (pfaInvest?.harPfaInvesterer) {
      obj.pfaPlusHigh = pfaInvest?.hoejFaktiskTotalFordelingProcent;
      obj.pfaPlusLow = pfaInvest?.lavFaktiskTotalFordelingProcent;
      obj.savings = pfaInvest?.opsparing ?? 0;
    } else if (pfaOptional?.harPfaValgfri) {
      obj.pfaPlusHigh = pfaOptional?.hoejFaktiskTotalFordelingProcent;
      obj.pfaPlusLow = pfaOptional?.lavFaktiskTotalFordelingProcent;
      obj.savings = pfaOptional?.opsparing ?? 0;
    } else {
      obj.pfaPlusHigh = 0;
      obj.pfaPlusLow = 0;
      obj.savings = 0;
    }

    return obj;
  }
}
