import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'mitpfa-show-agreement',
  templateUrl: './show-agreement-dialog.component.html',
  styleUrls: ['./show-agreement-dialog.component.scss']
})
export class ShowAgreementDialogComponent implements OnInit, AfterViewInit {
  agreement: string;
  srcdoc: SafeHtml;
  instantApproval: boolean;

  constructor(
    public dialogRef: MatDialogRef<ShowAgreementDialogComponent>,
    private sanitizer: DomSanitizer,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.srcdoc = this.sanitizer.bypassSecurityTrustHtml(this.agreement); // NOSONAR : this is a trusted source
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.el.nativeElement.getElementsByClassName(
        'mat-dialog-content'
      ).scrollTop = 0;
    }, 500);
  }
}
