import { Component, OnInit, inject, DestroyRef } from '@angular/core';
import { environment } from '../../environments/environment';
import { combineLatest } from 'rxjs';
import { MaeglerInfo, PensionskundeStore } from 'ds-api';
import { ContentUtilService, PageTrackingService } from '@pfa/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'mitpfa-messaging-wrapper',
  templateUrl: './mit-pfa-messaging-wrapper.component.html',
  styleUrls: ['./mit-pfa-messaging-wrapper.component.scss']
})
export class MitPfaMessagingWrapperComponent implements OnInit {
  private readonly pensionskundeStore: PensionskundeStore =
    inject(PensionskundeStore);
  private readonly contentUtils: ContentUtilService =
    inject(ContentUtilService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly pageTrackingService: PageTrackingService =
    inject(PageTrackingService);

  public channel: string;
  public isRaadgiver: boolean;
  public specialMessagingRules: boolean;
  public broker: MaeglerInfo;
  public phoneNumber: string;
  public initialized: boolean;

  public ngOnInit(): void {
    this.channel = environment.channel;

    combineLatest({
      pensionCustomer: this.pensionskundeStore.pensionskundeGet(),
      broker: this.pensionskundeStore.pensionskundeMaeglerinfoGet()
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(data => {
        this.broker = data.broker;
        this.isRaadgiver = data.pensionCustomer.isRaadgiver;
        this.specialMessagingRules = data.pensionCustomer.specialMessagingRules;

        if (this.broker.maeglerBetjent && this.broker.livnetAftale) {
          this.phoneNumber = data.broker.telefon;
        } else {
          this.phoneNumber = this.contentUtils.getContent('DL.KO01.C317');
        }

        this.initialized = true;
        this.pageTrackingService.trackContentLoaded();
      });
  }
}
