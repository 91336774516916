import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { combineLatest, take } from 'rxjs';
import { ExternalTransferService } from './external-transfer.service';
import { PensionsKundeGenerelleData } from 'ds-api';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'mitpfa-ayr-external-transfer',
  templateUrl: 'external-transfer.component.html'
})
export class ExternalTransferComponent implements OnInit {
  @Output() goTo = new EventEmitter();
  @Input() pensionCustomer: PensionsKundeGenerelleData;

  public transferredPoliciesUids?: string[];
  public error: boolean;

  private readonly externalTransferService: ExternalTransferService = inject(
    ExternalTransferService
  );
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  public ngOnInit(): void {
    this.externalTransferService
      .getBroker()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {},
        error: () => {
          this.error = true;
        }
      });

    combineLatest([
      this.externalTransferService.getRecommendationShortInfo(),
      this.externalTransferService.getExternalTransfer(),
      this.externalTransferService.getPensionCustomer()
    ])
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.goTo.emit();
        },
        error: () => {
          this.error = true;
        }
      });
  }
}
