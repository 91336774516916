import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  inject
} from '@angular/core';
import { InvestmentApiService } from '@pfa/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  ContentService,
  ContentUtilService,
  MiscTrackingService
} from '@pfa/core';
import { InsuranceInfo } from './dashboard-savings-investment.model';
import { Router } from '@angular/router';
import { InvesteringDetaljerSvar, InvesteringPolice, Profil } from 'ds-api';
import { InvestmentService, YouInvestService } from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-dashboard-savings-investment',
  templateUrl: './dashboard-savings-investment.component.html',
  styleUrls: ['./dashboard-savings-investment.component.scss']
})
export class DashboardSavingsInvestmentComponent implements OnInit, OnDestroy {
  private readonly investmentApiService: InvestmentApiService =
    inject(InvestmentApiService);
  private readonly contentService: ContentService = inject(ContentService);
  private readonly contentUtilService: ContentUtilService =
    inject(ContentUtilService);
  private readonly trackingService: MiscTrackingService =
    inject(MiscTrackingService);
  private readonly router: Router = inject(Router);
  public readonly youInvestService: YouInvestService = inject(YouInvestService);
  private readonly investmentService: InvestmentService =
    inject(InvestmentService);

  @Output() contentLoaded = new EventEmitter<void>();

  private unsubscribe = new Subject<void>();

  public investments: InvesteringDetaljerSvar;
  public primaryPolicy: InvesteringPolice | undefined;
  public insuranceInfo: InsuranceInfo;
  public showPrimaryKrAndLivnet: boolean;
  public noInvestments = true;
  public errorFetchingData: boolean;
  public hasMoreThanOneInvestmentType: boolean;
  public hasMoreThanOnePolicy: boolean;
  public hasPendingChanges: boolean;
  public hasSavingsInMoneyMarketFund: boolean;
  public hasPaidUpPolicesOnly: boolean;
  public initialised: boolean;

  public ngOnInit(): void {
    this.investmentApiService
      .get()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: investments => {
          this.investments = investments;

          this.primaryPolicy = this.investments.detaljer?.policer?.find(
            policy => policy.id === this.investments.detaljer?.primaerPolice
          );
          this.hasMoreThanOneInvestmentType =
            this.getHasMoreThanOneInvestmentType();
          this.hasMoreThanOnePolicy =
            this.investments.detaljer?.policer?.length > 1;

          const primaryInvestmentPolicy =
            this.investments.detaljer?.policer?.find(
              policy =>
                policy.id ===
                this.investments.detaljer?.primaerInvesteringsPolice
            );
          if (
            primaryInvestmentPolicy &&
            this.primaryPolicy &&
            this.primaryPolicy.id !== primaryInvestmentPolicy.id
          ) {
            this.showPrimaryKrAndLivnet = true;
          }

          this.noInvestments = !this.investments.detaljer?.policer?.some(
            policy => policy.type !== 'Forsikring'
          );

          this.hasPendingChanges = this.investments.detaljer.udestaaendeHandler;
          this.hasSavingsInMoneyMarketFund =
            this.investments.detaljer.savingsInMoneyMarketFund;
          this.hasPaidUpPolicesOnly = this.investments.detaljer?.policer?.every(
            policy => policy.tilstand === 'Fripolice'
          );

          this.mapTexts();
          this.contentService.languageState
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
              this.mapTexts();
            });
          this.errorFetchingData = false;
          this.initialised = true;
          this.contentLoaded.emit();
        },
        error: () => {
          this.errorFetchingData = true;
          this.initialised = true;
          this.contentLoaded.emit();
        }
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private mapTexts(): void {
    const profilesAllSources =
      this.primaryPolicy?.pfaInvesterer?.profiler?.filter(
        profile => profile.kilde === 'Alle'
      );

    const chosenProfile = profilesAllSources?.find(profile => profile.valgt);
    let pfaInvestProfile = '';
    if (
      this.primaryPolicy?.pfaInvesterer?.harPfaInvesterer ||
      (this.primaryPolicy?.tilstand === 'Fripolice' &&
        this.primaryPolicy?.pfaInvesterer?.muligt)
    ) {
      const profilesFreeSources =
        this.primaryPolicy?.pfaInvesterer?.profiler?.filter(
          profile => profile.kilde !== 'Alle'
        );

      const chosenFreeProfile = profilesFreeSources?.find(
        profile => profile.valgt
      );

      pfaInvestProfile = this.getPfaInvestProfile(
        chosenProfile,
        chosenFreeProfile
      );
    }
    this.initializeInsuranceInfo(pfaInvestProfile, chosenProfile);
  }

  private getHasMoreThanOneInvestmentType(): boolean {
    let investementTypesCounter = 0;
    if (this.primaryPolicy?.pfaInvesterer?.harPfaInvesterer) {
      investementTypesCounter++;
    }
    if (this.primaryPolicy?.duInvesterer?.harDuInvesterer) {
      investementTypesCounter++;
    }
    if (this.primaryPolicy?.pfaValgfri?.harPfaValgfri) {
      investementTypesCounter++;
    }
    return investementTypesCounter > 1;
  }

  private getPfaInvestProfile(
    chosenProfile: Profil | undefined,
    chosenFreeProfile: Profil | undefined
  ): string {
    let pfaInvestProfile = '';
    if (chosenProfile) {
      const chosenProfileText = this.investmentService.getProfileText(
        chosenProfile.id
      );

      pfaInvestProfile = this.contentUtilService.getContent(chosenProfileText);

      if (chosenFreeProfile) {
        const chosenFreeProfileText = this.investmentService.getProfileText(
          chosenFreeProfile.id
        );

        pfaInvestProfile += ` + ${this.contentUtilService.getContent(
          chosenFreeProfileText
        )}`;
      }
    }
    return pfaInvestProfile;
  }

  private getPfaInvestRisk(chosenProfile: Profil): string {
    let pfaInvestRisk = '';
    if (chosenProfile) {
      switch (chosenProfile.id) {
        case 'CAREFUL':
          pfaInvestRisk = this.contentUtilService.getContent('DL.FS03.C22');
          break;
        case 'LOW':
          pfaInvestRisk = this.contentUtilService.getContent('DL.FS03.C48');
          break;
        case 'MEDIUM':
          pfaInvestRisk = this.contentUtilService.getContent('DL.FS03.C23');
          break;
        case 'HIGH':
          pfaInvestRisk = this.contentUtilService.getContent('DL.FS03.C24');
          break;
      }
    }
    return pfaInvestRisk;
  }

  public goToInvestment(): void {
    if (!this.errorFetchingData && !this.primaryPolicy?.fejlet) {
      this.trackingService.trackNavigationCard(
        'Savings section',
        'Your risik profile',
        '/mineinvesteringer'
      );
      this.router.navigate(['/mineinvesteringer']);
    }
  }

  private initializeInsuranceInfo(
    pfaInvestProfile: string,
    chosenProfile: Profil
  ): void {
    this.insuranceInfo = new InsuranceInfo();
    this.insuranceInfo.header =
      this.contentUtilService.getContent('DL.FS03.C32');
    this.insuranceInfo.mainText =
      this.contentUtilService.getContent('DL.FS03.C30');
    if (this.hasPaidUpPolicesOnly) {
      this.insuranceInfo.mainText =
        this.contentUtilService.getContent('DL.FS03.C52');
    } else {
      if (pfaInvestProfile) {
        this.setPfaInvestHeader(pfaInvestProfile, chosenProfile);
      }
      if (
        this.primaryPolicy?.pfaValgfri?.harPfaValgfri &&
        !this.youInvestService.check100YouInvest(this.primaryPolicy)
      ) {
        this.insuranceInfo.mainText =
          this.contentUtilService.getContent('DL.FS03.C33');
      }
      if (this.noInvestments) {
        this.insuranceInfo.mainText =
          this.contentUtilService.getContent('DL.FS03.C35');
      } else {
        if (this.primaryPolicy?.type === 'Gennemsnit') {
          this.insuranceInfo.mainText =
            this.contentUtilService.getContent('DL.FS03.C27');
        }
      }
    }
  }

  private setPfaInvestHeader(
    pfaInvestProfile: string,
    chosenProfile: Profil
  ): void {
    if (pfaInvestProfile.indexOf('+') === -1 && chosenProfile.id !== 'G') {
      // do NOT have dual pfa invest profiles
      this.insuranceInfo.header = this.contentUtilService.getContent(
        'DL.FS03.C21',
        this.getPfaInvestRisk(chosenProfile)
      );
    }
    this.insuranceInfo.mainText = pfaInvestProfile;
  }
}
