<nav aria-label="HeaderMenu">
  <div class="menu-logo">
    <co-icon-logo
      class="header--logo"
      (click)="gotoFrontpage()"
      (keyup)="gotoFrontpage()"
      data-test-id="pfa-header-logo"
    ></co-icon-logo>
  </div>

  <ng-container
    *ngIf="advisingService.newBusinessAdvisory$ | async as digitalSalgContext"
  >
    <div class="centered-menu">
      <mitpfa-menu-item
        *ngIf="!digitalSalgContext.digitalSalgRaadgivning"
        [menuItem]="savingsMenuItem"
        [isAdvisor]="pensionCustomer?.isRaadgiver"
        [isMenuSelected]="isMenuSelected(savingsMenuItem.name)"
        [selectedSubMenu]="getSelectedMenuItem()"
        [dataTestId]="savingsMenuItem.dataTestId"
        [menuOpen]="getMenuItemOpen()"
        (openCloseMenu)="openCloseMenu($event)"
      >
        <co-icon-savings icon class="icons"></co-icon-savings>
      </mitpfa-menu-item>
      <mitpfa-menu-item
        *ngIf="!digitalSalgContext.digitalSalgRaadgivning"
        [menuItem]="insuranceMenuItem"
        [isAdvisor]="pensionCustomer?.isRaadgiver"
        [isMenuSelected]="isMenuSelected(insuranceMenuItem.name)"
        [selectedSubMenu]="getSelectedMenuItem()"
        [menuOpen]="getMenuItemOpen()"
        (openCloseMenu)="openCloseMenu($event)"
        [dataTestId]="insuranceMenuItem.dataTestId"
      >
        <co-icon-insurance icon class="icons"></co-icon-insurance>
      </mitpfa-menu-item>
      <mitpfa-menu-item
        *ngIf="!digitalSalgContext.digitalSalgRaadgivning"
        [menuItem]="advantageMenuItem"
        [isAdvisor]="pensionCustomer?.isRaadgiver"
        [isMenuSelected]="isMenuSelected(advantageMenuItem.name)"
        [selectedSubMenu]="getSelectedMenuItem()"
        [menuOpen]="getMenuItemOpen()"
        (openCloseMenu)="openCloseMenu($event)"
        [dataTestId]="advantageMenuItem.dataTestId"
      >
        <co-icon-advantage icon class="icons"></co-icon-advantage>
      </mitpfa-menu-item>

      <div
        *ngIf="
          !digitalSalgContext.digitalSalgRaadgivning &&
          showPensionCheckKillswitch
        "
        class="menu-item--dynamic--counseling"
      >
        <a class="profile" [routerLink]="'/handlingscenter'">
          <mitpfa-pension-check-indicator
            (click)="adobeTrackingClickPensionCheck()"
            (keyup)="adobeTrackingClickPensionCheck()"
          ></mitpfa-pension-check-indicator>
        </a>
      </div>
    </div>

    <mitpfa-menu-item
      *ngIf="!digitalSalgContext.digitalSalgRaadgivning"
      [menuItem]="messagesMenuItem"
      [isAdvisor]="pensionCustomer?.isRaadgiver"
      [specialMenuItem]="true"
      [isMenuSelected]="isMenuSelected(messagesMenuItem.name)"
      [selectedSubMenu]="getSelectedMenuItem()"
      [menuOpen]="getMenuItemOpen()"
      (openCloseMenu)="openCloseMenu($event)"
      [dataTestId]="messagesMenuItem.dataTestId"
    >
      <co-icon-envelope icon class="icons"></co-icon-envelope>
      <co-notification
        percentfilled
        class="header--notification"
        [amount]="
          notifications.unreadMessages +
          notifications.unreadNotifications +
          notifications.antalAendringer
        "
      >
      </co-notification>
    </mitpfa-menu-item>
    <mitpfa-menu-item
      *ngIf="!digitalSalgContext.digitalSalgRaadgivning"
      [menuItem]="profileMenuItem"
      [isAdvisor]="pensionCustomer?.isRaadgiver"
      [specialMenuItem]="true"
      [lastVisit]="pensionCustomer?.sidsteBesoeg"
      [isMenuSelected]="isMenuSelected(profileMenuItem.name)"
      [selectedSubMenu]="getSelectedMenuItem()"
      [menuOpen]="getMenuItemOpen()"
      (openCloseMenu)="openCloseMenu($event)"
      [dataTestId]="profileMenuItem.dataTestId"
    >
      <co-icon-profile icon class="icons"></co-icon-profile>
      <mitpfa-percent-filled
        percentfilled
        class="header--notification"
      ></mitpfa-percent-filled>
    </mitpfa-menu-item>

    <mitpfa-menu-item
      [isAdvisor]="pensionCustomer?.isRaadgiver"
      *ngIf="digitalSalgContext.digitalSalgRaadgivning"
      [menuItem]="newBusinessAdvisoryMenuItem"
      [specialMenuItem]="true"
      [lastVisit]="pensionCustomer?.sidsteBesoeg"
      [isMenuSelected]="isMenuSelected(newBusinessAdvisoryMenuItem.name)"
      [selectedSubMenu]="getSelectedMenuItem()"
      [menuOpen]="getMenuItemOpen()"
      (openCloseMenu)="openCloseMenu($event)"
    >
      <co-icon-profile icon class="icons"></co-icon-profile>
      <mitpfa-percent-filled
        percentfilled
        class="header--notification"
      ></mitpfa-percent-filled>
    </mitpfa-menu-item>
  </ng-container>

  <mitpfa-menu-item-mobile
    [isAdvisor]="pensionCustomer?.isRaadgiver"
    [menuItems]="mobileMenuItems"
    [lastVisit]="pensionCustomer?.sidsteBesoeg"
    [isMenuOpen]="getMenuItemOpen() === 'MOBILE'"
    [selectedSubMenuName]="getSelectedMenuItem()?.name ?? 'UNDEFINED'"
    [notifications]="
      notifications.unreadMessages +
      notifications.unreadNotifications +
      notifications.antalAendringer
    "
    (openCloseMenu)="openCloseMenu($event)"
  >
  </mitpfa-menu-item-mobile>
</nav>
<div
  (click)="closeTopMenu()"
  (keyup)="closeTopMenu()"
  *ngIf="getMenuItemOpen() !== 'UNDEFINED'"
  class="overlay"
></div>
