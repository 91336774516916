import { Component, OnInit } from '@angular/core';
import { MessagingService } from '../messaging.service';
import { GlobalWorkingService, ScrollToService } from '@pfaform';
import { ContentService } from '@pfa/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  UntypedFormControl,
  Validators,
  UntypedFormGroup
} from '@angular/forms';
import { noInitialAnimation, slideInRight, slideInLeft } from '@pfa/animations';
import { Observable } from 'rxjs';
import {
  ContactData,
  CustomerProfile,
  CustomerprofileStore,
  HentKategoriListSvar
} from 'ds-api';
import { map } from 'rxjs/operators';

enum Step {
  category,
  subject,
  newMessage
}

@Component({
  selector: 'co-new-message',
  templateUrl: './new-message.component.html',
  styleUrls: ['./new-message.component.scss'],
  animations: [noInitialAnimation, slideInRight, slideInLeft]
})
export class NewMessageComponent implements OnInit {
  nonCustomer: boolean;
  channel: string;
  showContactInput: boolean;
  lang: string;
  attachments: any[];
  showNew: boolean;
  step = Step;
  currentStep: Step = Step.category;
  showDynamicForm: boolean;
  thread: any;
  getCategoriesFailed: boolean;
  categories: any[];
  sendingMessage: boolean;
  selectedTopic: any;
  sendMessageFailed: boolean;
  virusDetected: boolean;
  selectedCategory: any;
  dynamicFormEventListenerInitialized: boolean;
  contactInfoFormGroup: UntypedFormGroup;
  enableAllValidationErrors: boolean;
  contactData: ContactData | undefined;
  allowedFileSize = '';
  allowedFileExtensions = [
    'jpeg',
    'png',
    'gif',
    'bmp',
    'pdf',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'ppt',
    'pptx'
  ];
  contactData$: Observable<ContactData | undefined>;
  title = new UntypedFormControl('', [Validators.required]);
  body = new UntypedFormControl('', [Validators.required]);
  cpr = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern('\\d{6}-?\\d{4}')
  ]);
  email = new UntypedFormControl('', [Validators.required, Validators.email]);

  constructor(
    private readonly service: MessagingService,
    private readonly scrollToService: ScrollToService,
    private readonly contentService: ContentService,
    private readonly customerprofileStore: CustomerprofileStore,
    private readonly globalWorkingService: GlobalWorkingService,
    public popup: MatDialogRef<NewMessageComponent>
  ) {}

  ngOnInit() {
    this.nonCustomer = this.service.isNonCustomer();
    this.channel = this.service.getChannel();
    this.showContactInput = this.service.isShowContactInputEnabled();
    this.lang = this.contentService.getLanguage();
    this.attachments = [];
    this.showNew = false;
    this.showDynamicForm = false;
    this.thread = {
      message: {
        fromCustomer: true
      }
    };

    if (this.showContactInput) {
      this.contactData$ = this.customerprofileStore
        .customerprofileGet()
        .pipe(
          map((customerProfile: CustomerProfile) => customerProfile.contactData)
        );
    }

    let categoryService: Observable<HentKategoriListSvar>;
    if (this.nonCustomer) {
      categoryService = this.service.getNonCustomerCategories();
    } else {
      categoryService = this.service.getCategories();
    }

    categoryService.subscribe(
      data => {
        if (!data.fejlet) {
          this.categories = data.categories;
          if (this.categories) {
            this.categories.forEach(function (category) {
              switch (category.id) {
                case 'a':
                  category.imageUrl = '/assets/images/01minpension.png';
                  break;
                case 'b':
                  category.imageUrl = '/assets/images/02minforsikring.png';
                  break;
                case 'c':
                  category.imageUrl = '/assets/images/03mithelbred.png';
                  break;
                case 'd':
                  category.imageUrl = '/assets/images/04aendringerimitliv.png';
                  break;
                case 'e':
                  category.imageUrl = '/assets/images/05teknisksupport.png';
                  break;
                case 'f':
                  category.imageUrl = '/assets/images/06minkundeoplevelse.png';
                  break;
                case 'h':
                  category.imageUrl = '/assets/images/Bank.png';
                  break;
                case 'i':
                  category.imageUrl = '/assets/images/Behandler.png';
                  break;
                case 'j':
                  category.imageUrl = '/assets/images/Sygdom.png';
                  break;
                case 'k':
                  category.imageUrl = '/assets/images/Indbetaling.png';
                  break;
                case 'l':
                  category.imageUrl =
                    '/assets/images/Administrationsportal.png';
                  break;
                case 'm':
                  category.imageUrl = '/assets/images/Virksomhed.png';
                  break;
                case 'n':
                  category.imageUrl = '/assets/images/organisationer.png';
                  break;
                case 'o':
                  category.imageUrl = '/assets/images/ny-kunde.png';
                  break;
                case 'p':
                  category.imageUrl = '/assets/images/maegler.png';
                  break;
                case 'q':
                  category.imageUrl =
                    '/assets/images/non_customer_new_customer.svg';
                  break;
                case 'r':
                  category.imageUrl =
                    '/assets/images/non_customer_former_customer.svg';
                  break;
                case 's':
                  category.imageUrl =
                    '/assets/images/non_customer_bereaved.svg';
                  break;
                case 't':
                  category.imageUrl =
                    '/assets/images/non_customer_logo_let_pension.svg';
                  break;
                case 'u':
                  category.imageUrl = '/assets/images/ikon_liv.png';
                  break;
                case 'v':
                  category.imageUrl = '/assets/images/Forsikringer.svg';
                  break;
              }
            });
            this.getCategoriesFailed = false;
          } else {
            this.getCategoriesFailed = true;
            this.categories = [];
          }
        } else {
          this.getCategoriesFailed = true;
          this.categories = [];
        }
      },
      () => {
        this.getCategoriesFailed = true;
        this.categories = [];
      }
    );
  }

  scrollToError() {
    this.scrollToService.scrollTo('#send-message-failed');
  }

  sendMessage() {
    this.enableAllValidationErrors = true;
    this.globalWorkingService.show();
    if (this.isContactInfoValid()) {
      this.sendingMessage = true;
      this.thread.topic = this.selectedTopic.id;

      this.service
        .sendMessage(
          this.thread,
          this.attachments,
          this.contactInfoFormGroup ? this.contactInfoFormGroup.value : null
        )
        .subscribe({
          next: data => {
            this.globalWorkingService.hide();
            if (!data.fejlet) {
              this.popup.close(data.id);
              this.sendMessageFailed = false;
              if (this.service.isShowContactInputEnabled()) {
                this.customerprofileStore.invalidatecustomerprofileIndex();
              }
            } else {
              this.sendMessageError();
            }
            this.sendingMessage = false;
          },
          error: error => {
            this.sendMessageError(error);
            this.globalWorkingService.hide();
          }
        });
    }
  }

  contactInfoFormInit(form: UntypedFormGroup) {
    this.contactInfoFormGroup = form;
  }

  isContactInfoValid() {
    if (this.contactInfoFormGroup) {
      this.contactInfoFormGroup.updateValueAndValidity();
      return this.contactInfoFormGroup.valid;
    } else {
      return true;
    }
  }

  sendMessageError(error?: { status: number }) {
    this.sendingMessage = false;
    this.sendMessageFailed = true;
    if (error && error.status === 400) {
      this.virusDetected = true;
    }
    this.scrollToError();
  }

  next() {
    if (
      this.selectedTopic.dynamicFormId &&
      this.selectedTopic.dynamicFormId[this.lang]
    ) {
      this.showDynamicForm = true;
    } else {
      if (this.channel === 'mitpfa') {
        this.allowedFileSize = '15MB';
      } else {
        this.allowedFileSize = '10MB';
      }

      if (this.service.isShowContactInputEnabled()) {
        this.customerprofileStore.customerprofileGet().subscribe(profile => {
          this.contactData = profile.contactData;
        });
        this.showNew = true;
      } else {
        this.showNew = true;
      }
    }
    this.currentStep++;
  }

  showTopics(category) {
    this.currentStep++;
    category.showTopics = !category.showTopics;
    this.selectedCategory = category;
  }

  showCategories() {
    this.currentStep--;
    if (this.selectedCategory) {
      this.selectedCategory.showTopics = false;
      delete this.selectedCategory;
    }
  }

  selectTopic(topic) {
    if (this.selectedTopic) {
      this.selectedTopic.selected = false;
    }
    topic.selected = true;
    this.selectedTopic = topic;
    this.next();
  }

  addAttachment(file) {
    this.attachments.push(file);
    if (this.attachments.length < 4) {
      setTimeout(() => {
        this.scrollToService.scrollTo('#attachment-scroll-id');
      }, 100);
    }
  }

  removeAttachment(file) {
    if (this.virusDetected) {
      this.sendMessageFailed = false;
      this.virusDetected = false;
    }
    this.attachments.splice(this.attachments.indexOf(file), 1);
  }

  dynamicFormBackClick() {
    this.currentStep--;
    this.dynamicFormEventListenerInitialized = false;
    this.showDynamicForm = false;
  }

  /*The dynamic form for Kundedialog is an Angular web element which is interoperable with vanila JS events.*/
  setupExternalSubmitFinishedListener() {
    //Remove
    this.getDynamicFormElement()?.removeEventListener(
      'dynamic-form-submit-finished',
      event => this.onSubmitDynamicFormFinished(event)
    );
    //Add
    this.getDynamicFormElement()?.addEventListener(
      'dynamic-form-submit-finished',
      event => this.onSubmitDynamicFormFinished(event)
    );
  }

  onSubmitDynamicFormFinished(event) {
    this.globalWorkingService.hide();
    if (event.detail.data) {
      if (event.detail.data.valideringsFejl) {
        //Do nothing the form will focus the error
      } else if (!event.detail.data.fejlet) {
        this.popup.close(event.detail.data.id);
      } else {
        document
          .querySelector('#send-message-failed-dynamic-form')
          ?.scrollIntoView(false);
      }
    }
  }

  submitDynamicForm() {
    if (!this.dynamicFormEventListenerInitialized) {
      this.dynamicFormEventListenerInitialized = true;
      this.globalWorkingService.show();
      this.setupExternalSubmitFinishedListener();
    }
    this.getDynamicFormElement()?.dispatchEvent(
      new CustomEvent('dynamic-form-submit')
    );
  }

  getDynamicFormElement(): Element | null {
    return document.querySelector('dynamic-form')
      ? document.querySelector('dynamic-form')
      : document.querySelector('co-dynamic-form');
  }

  closePopup() {
    this.popup.close();
  }
}
