import {
  DynamicAdvisingOfferidType,
  DynamicAdvisingRecommendationCategoryType
} from 'ds-api';
import { AlertColor } from '@pfa/models';

export class NbaCard {
  index: number;
  id: DynamicAdvisingOfferidType;
  category: DynamicAdvisingRecommendationCategoryType;
  hash: string;
  severity: number;
  headline: string;
  bodyText: string;
  gotoUrl: string;
  gotoText: string;
  relevantOfferIds: DynamicAdvisingOfferidType[];

  constructor(
    index: number,
    id: DynamicAdvisingOfferidType,
    category: DynamicAdvisingRecommendationCategoryType,
    hash: string,
    severity: number
  ) {
    this.index = index;
    this.id = id;
    this.category = category;
    this.hash = hash;
    this.severity = severity;
  }

  alertColor(): AlertColor {
    switch (this.severity) {
      case 1:
        return AlertColor.GREEN;
      case 2:
        return AlertColor.YELLOW;
      case 3:
        return AlertColor.RED;
      default:
        return AlertColor.GREY;
    }
  }
}
