import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { PensionsKundeGenerelleData } from 'ds-api';
import { ContentUtilService } from '@pfa/core';

@Component({
  selector: 'mitpfa-onboarding-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeComponent implements OnInit {
  @Output() start = new EventEmitter();
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Input() resumeFlow: boolean;
  @Input() isUpdateError: boolean;
  public firstname: string;
  public trafficlight = 1;
  public showText = false;

  private readonly contentUtilService: ContentUtilService =
    inject(ContentUtilService);
  private readonly ref: ChangeDetectorRef = inject(ChangeDetectorRef);

  public ngOnInit(): void {
    this.firstname = this.pensionCustomer?.fornavn
      ? this.pensionCustomer?.fornavn
      : this.contentUtilService.getContent('DL.AP02.C44');

    const timeout = setInterval(() => {
      this.trafficlight = this.trafficlight + 1;
      this.ref.detectChanges();
      if (this.trafficlight >= 3) {
        clearInterval(timeout);
      }
    }, 1000);

    setTimeout(() => {
      this.showText = true;
      this.ref.detectChanges();
    }, 5000);
  }

  public begin(): void {
    this.start.emit();
  }
}
