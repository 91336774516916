import { Component, OnInit } from '@angular/core';
import { DeviceService } from '@mitpfa/shared';
import { ContentUtilService } from '@pfa/core';

@Component({
  selector: 'mitpfa-operation-status',
  templateUrl: './operation-status.component.html',
  styleUrls: ['./operation-status.component.scss']
})
export class OperationStatusComponent implements OnInit {
  public showOperationStatus = false;
  public operationStatusText: string;

  constructor(
    private readonly contentUtilService: ContentUtilService,
    private readonly deviceService: DeviceService
  ) {}

  ngOnInit(): void {
    this.handleBanner('DL.FS01.C33');
    if (!this.showOperationStatus && this.deviceService.isIOS()) {
      this.handleBanner('DL.FS01.C41');
    }
    if (!this.showOperationStatus && this.deviceService.isAndroid()) {
      this.handleBanner('DL.FS01.C43');
    }
    if (!this.showOperationStatus && this.deviceService.isNative()) {
      this.handleBanner('DL.FS01.C42');
    }
  }

  private handleBanner(contentId: string): void {
    this.operationStatusText = this.contentUtilService.getContent(contentId);
    if (
      this.operationStatusText?.trim() !== '' &&
      this.operationStatusText !== contentId
    ) {
      this.showOperationStatus = true;
    }
  }

  hideOperationStatusText(): void {
    this.showOperationStatus = false;
  }
}
