import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ConsentsApiService, SaveConsentDTO } from '@pfa/api';
import {
  ConsentsConfigurationService,
  ConsentState,
  ConsentType,
  GlobalWorkingService,
  RefinedConsentInfo
} from '@pfaform';
import { ConsentTrackingService } from '@pfa/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { ConsentInfo, PensionsKundeGenerelleData } from 'ds-api';
import { slideInBottom, slideOutBottomWhole } from '@pfa/animations';

@Component({
  selector: 'mitpfa-onboarding-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss'],
  animations: [slideInBottom, slideOutBottomWhole]
})
export class ConsentComponent implements OnInit {
  @Output() goTo = new EventEmitter();
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Input() isUpdateError: boolean;
  public pfaFocusConsent: RefinedConsentInfo | undefined;
  public isMarketingPensionOnly: boolean;
  public isStateGIVEN: boolean;
  private channelControls: { [key: string]: AbstractControl };
  private consentForm: UntypedFormGroup;
  public isOpen: boolean;

  private readonly consentsConfigurationService: ConsentsConfigurationService =
    inject(ConsentsConfigurationService);
  private readonly consentsApiService: ConsentsApiService =
    inject(ConsentsApiService);
  private readonly trackingService: ConsentTrackingService = inject(
    ConsentTrackingService
  );
  private readonly globalWorkingService: GlobalWorkingService =
    inject(GlobalWorkingService);

  public ngOnInit(): void {
    if (this.pensionCustomer.consents) {
      this.pfaFocusConsent = this.getPfaFocusConsent(
        this.pensionCustomer.consents
      );

      if (this.pfaFocusConsent) {
        this.initializeConsentsForm(this.pfaFocusConsent);
      }

      this.isMarketingPensionOnly = this.pensionCustomer.consents.some(
        consent => consent.type === ConsentType.MarketingPensionOnly
      );
    }
    this.isStateGIVEN =
      this.pfaFocusConsent && this.pfaFocusConsent.state === 'GIVEN';
  }

  private getPfaFocusConsent(
    consents: ConsentInfo[]
  ): RefinedConsentInfo | undefined {
    return this.consentsConfigurationService
      .refineConsents(consents)
      .find(
        consentInfo =>
          consentInfo.type === ConsentType.Marketing ||
          consentInfo.type === ConsentType.MarketingPensionOnly
      );
  }

  private initializeConsentsForm(consent: ConsentInfo): void {
    const isGiven = consent.state === ConsentState.GIVEN;
    this.consentForm = new UntypedFormGroup({
      main: new UntypedFormControl(isGiven),
      channels: consent.channels.reduce((form, channel) => {
        form.addControl(
          channel.channelKey,
          new UntypedFormControl(channel.given)
        );
        return form;
      }, new UntypedFormGroup({}))
    });
    this.channelControls = (
      this.consentForm.controls.channels as UntypedFormGroup
    ).controls;
  }

  public consentAnswer(answer: boolean): void {
    this.globalWorkingService.show();

    if (this.pfaFocusConsent) {
      const saveConsentData: SaveConsentDTO = {
        type: this.pfaFocusConsent.type,
        state: answer ? ConsentState.GIVEN : ConsentState.REVOKED,
        version: this.pfaFocusConsent.version,
        channels: answer
          ? Object.entries(this.channelControls).map(entry => ({
              channelKey: entry[0],
              given: true
            }))
          : []
      };

      this.consentsApiService.updateConsent(saveConsentData).subscribe(() => {
        this.trackingService.trackConsentSubscribed(ConsentType.Marketing);
        this.globalWorkingService.hide();
        this.goTo.emit();
      });
    }
  }

  public next(): void {
    this.goTo.emit();
  }
}
