import { Component, inject, OnInit } from '@angular/core';
import {
  GlobalWorkingService,
  PopupComponent,
  PopupConfiguration
} from '@pfaform';
import { Router } from '@angular/router';
import { ContentUtilService } from '@pfa/core';
import { AdvisorOptions, IndbetalingSikring, TilbudOverblik } from 'ds-api';
import { NewBusinessAdvisoryService } from '@pfa/services';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PopupStyle } from '@pfa/models';

@Component({
  selector: 'mitpfa-new-business-advisory',
  templateUrl: './new-business-advisory.component.html',
  styleUrls: ['./new-business-advisory.component.scss']
})
export class NewBusinessAdvisoryComponent implements OnInit {
  private readonly advisingService: NewBusinessAdvisoryService = inject(
    NewBusinessAdvisoryService
  );
  private readonly router: Router = inject(Router);
  private readonly globalWorking: GlobalWorkingService =
    inject(GlobalWorkingService);
  private readonly contentUtilService: ContentUtilService =
    inject(ContentUtilService);
  private readonly dialog: MatDialog = inject(MatDialog);

  public overview$ = this.advisingService.getOverview();
  public advisorOptions$ = this.advisingService.getAdvisorOptions('NEW');
  public pricelistUrl: string;
  public savePaymentsFail = false;
  public switchHsFail = false;
  public saveIndbetalingSikringFail = false;
  public errorMessage = '';

  public ngOnInit(): void {
    this.advisingService.newBusinessAdvisory$.subscribe(data => {
      this.pricelistUrl = `/prisliste/${data.digitalSalgPoliceNr}`;
    });

    // Tell subscribers to update CustomerProfile in case 'initraadgivning' updated it
    this.notifyProfileUpdated();
  }

  public onSavePayments(
    overview: TilbudOverblik,
    payments: { employer: number; employee: number }
  ): void {
    this.savePaymentsFail = false;
    this.globalWorking.show();
    this.advisingService
      .savePayments(overview.minObligatoriskIndbetaling, payments)
      .subscribe({
        next: () => this.onActionDone(),
        error: () => {
          this.globalWorking.hide();
          this.savePaymentsFail = true;
        }
      });
  }

  public onSaveVoluntaryPayment(payment: number): void {
    this.savePaymentsFail = false;
    this.globalWorking.show();
    this.advisingService.saveVoluntaryPayment(payment).subscribe({
      next: () => this.onActionDone(),
      error: () => {
        this.globalWorking.hide();
        this.savePaymentsFail = true;
      }
    });
  }

  private onActionDone(): void {
    this.globalWorking.hide();
    this.overview$ = this.advisingService.getOverview();
  }

  public onIndbetalingSikringSelected(
    indbetalingSikring: IndbetalingSikring
  ): void {
    this.saveIndbetalingSikringFail = false;
    this.globalWorking.show();
    this.advisingService.saveIndbetalingSikring(indbetalingSikring).subscribe({
      next: () => this.onActionDone(),
      error: () => {
        this.globalWorking.hide();
        this.saveIndbetalingSikringFail = true;
      }
    });
  }

  public onPartialSchemeSelected(partialSchemeId: number): void {
    this.switchHsFail = false;
    this.globalWorking.show();
    this.advisingService.switchHs(partialSchemeId).subscribe({
      next: result => {
        this.globalWorking.hide();
        if (result.success) {
          this.notifyProfileUpdated();
          this.overview$ = this.advisingService.getOverview();
        } else {
          this.switchHsFail = true;
        }
      },
      error: () => {
        this.globalWorking.hide();
        this.switchHsFail = true;
      }
    });
  }

  private notifyProfileUpdated(): void {
    const currentValue = window.localStorage.getItem('updateCustomerProfile');
    window.localStorage.setItem(
      'updateCustomerProfile',
      currentValue ? '' : '1'
    );
  }

  public close(): void {
    this.globalWorking.show();
    this.errorMessage = null;
    this.advisingService.afslutRaadgivning().subscribe({
      next: () => {
        this.globalWorking.hide();
        window.close();
      },
      error: () => {
        this.globalWorking.hide();
        this.errorMessage = this.contentUtilService.getContent('DL.TR01.396');
      }
    });
  }

  public continue(overview: TilbudOverblik): void {
    if (
      overview.policyType === 'CONTINUATION' &&
      overview.variantCode === 0 && // Lederpension
      overview.viderefoerelseSammenligning.risikoForhoejet
    ) {
      const warningPopupConfiguration: PopupConfiguration = {
        content: 'DL.TR01.C541',
        positiveButtonLabel: 'Global.ja',
        negativeButtonLabel: 'Global.nej'
      };
      const warningPopup: MatDialogRef<PopupComponent> = this.dialog.open(
        PopupComponent,
        {
          disableClose: true,
          panelClass: PopupStyle.PopupSmall,
          data: warningPopupConfiguration
        }
      );
      warningPopup.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.navigateToReleaseOffer(overview);
        }
      });
    } else {
      this.navigateToReleaseOffer(overview);
    }
  }

  private navigateToReleaseOffer(overview: TilbudOverblik): void {
    if (overview.companyOrPrivate === 'Privat') {
      this.router.navigate(['/advisor/new-business/release-offer'], {
        state: { data: overview }
      });
    } else {
      this.globalWorking.show();
      this.errorMessage = null;
      this.advisingService.savePayerId().subscribe({
        next: () => {
          this.globalWorking.hide();
          this.router.navigate(['/advisor/new-business/release-offer'], {
            state: { data: overview }
          });
        },
        error: () => {
          this.globalWorking.hide();
          this.errorMessage =
            this.contentUtilService.getContent('DL.TR01.C397');
        }
      });
    }
  }

  public checkMinIndbetalingTilOpsparing(
    overview: TilbudOverblik,
    advisorOptions: AdvisorOptions
  ): boolean {
    return !(
      overview.policyType === 'CONTINUATION' &&
      overview.variantCode === 1 &&
      overview.indbetalingTilOpsparing <
        advisorOptions.minIndbetalingTilOpsparing
    );
  }
}
