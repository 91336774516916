<div *ngIf="!isOpen" class="wrapper">
  <p
    class="align--center header text--manchet spacing-unit--one--half-under"
    [coContent]="isStateGIVEN ? 'DL.AP02.C12' : 'DL.VK01.C30'"
  ></p>
  <div class="card-container">
    <co-card *ngIf="isStateGIVEN">
      <p class="text--manchet headline" [coContent]="'DL.AP02.C12'"></p>
      <p [coContent]="'DL.AP02.C15'"></p>
      <p
        [coContent]="!isMarketingPensionOnly ? 'DL.AP02.C16' : 'DL.AP02.C29'"
      ></p>
      <p [coContent]="'DL.AP02.C17'"></p>
      <p [coContent]="'DL.AP02.C45'"></p>
      <button
        mat-raised-button
        [coContent]="'DL.VK01.C37'"
        (click)="next()"
        class="move-on"
        data-test-id="acceptConsent"
      ></button>
    </co-card>
    <co-card *ngIf="!isStateGIVEN">
      <div class="consent-given--container">
        <mitpfa-svg-information class="image"></mitpfa-svg-information>
        <div class="body-text">
          <h5 [coContent]="'DL.VK01.C31'"></h5>
          <p [coContent]="'DL.VK01.C32'"></p>
          <p [coContent]="'DL.VK01.C57'"></p>
        </div>
      </div>
      <co-accordion
        [title]="'DL.VK01.C33'"
        [initExpanded]="false"
        class="accordion--black-07 accordion--flat accordion--contrast accordion--slim policy-choice-accordion"
      >
        <span class="text--note" [coContent]="'DL.VK01.C35'"></span>&nbsp;
        <a
          [href]=""
          class="link"
          coContent="DL.VK01.C36"
          (click)="isOpen = true"
        ></a>
      </co-accordion>
      <div class="button-wrapper align--center">
        <button
          mat-raised-button
          class="button-secondary"
          [coContent]="'DL.VK01.C34'"
          (click)="consentAnswer(true)"
          data-test-id="acceptAndSaveConsentAccept"
        ></button>
      </div>
    </co-card>
    <co-alert class="alert-warning" *ngIf="isUpdateError">
      <p [coContent]="'DL.VK01.C44'"></p>
    </co-alert>
  </div>
</div>

<div
  *ngIf="isOpen"
  class="details--container"
  [@slideInBottom]="{ value: '', params: { duration: 300, delay: 0 } }"
  [@slideOutBottomWhole]="{
    value: '',
    params: { delay: 0, duration: 400 }
  }"
>
  <div class="details--header"></div>
  <div class="details--wrapper">
    <div class="content">
      <co-icon-times
        class="close icon-fill__large--greys-10 grey_background"
        (click)="isOpen = false"
      >
      </co-icon-times>
      <h3 coContent="DL.ST01.C235"></h3>
      <p coContent="DL.ST01.C236"></p>
      <p coContent="DL.ST01.C210"></p>
      <p coContent="DL.ST01.C211"></p>
      <p coContent="DL.ST01.C212"></p>
      <p coContent="DL.ST01.C213"></p>
      <p coContent="DL.ST01.C214"></p>
      <p coContent="DL.ST01.C215"></p>
    </div>
  </div>
</div>
