<div class="text--container bottom-space">
  <div class="header text--manchet">
    <div *ngIf="!changeHeadline" [coContent]="'DL.VK01.C38'"></div>
    <div
      *ngIf="changeHeadline && showInvestment"
      [coContent]="'DL.VK01.C121'"
    ></div>
    <div
      *ngIf="changeHeadline && !showInvestment && showInsurance"
      [coContent]="'DL.VK01.C129'"
    ></div>
  </div>
</div>

<mitpfa-onboarding-step-animation
  [firstCard]="firstCard"
  [secondCard]="secondCard"
  [extraCards]="extraCards"
  [doneCards]="1"
>
  <ng-template #firstCard>
    <mitpfa-svg-savings class="mobile--step1"></mitpfa-svg-savings>
    <div class="wrapper">
      <div>
        <div class="headline">
          <div class="text--grey text--bold" [coContent]="'DL.VK01.C39'"></div>
          <div class="number--icon disabled">2</div>
        </div>
        <div class="text--grey body-text" [coContent]="'DL.VK01.C40'"></div>
        <button
          mat-raised-button
          class="mat-focus-indicator mat-button-bas move-on"
          disabled="true"
          [coContent]="'DL.VK01.C41'"
        ></button>
      </div>
      <div class="container--desktop">
        <mitpfa-svg-savings class="image--desktop"></mitpfa-svg-savings>
      </div>
    </div>
  </ng-template>
  <ng-template #secondCard>
    <ng-container *ngIf="showInvestment">
      <mitpfa-svg-investmentguide
        class="image--mobile--step2"
      ></mitpfa-svg-investmentguide>
      <div class="wrapper">
        <div>
          <div class="headline">
            <div
              class="text--color-primary text--bold"
              [coContent]="'DL.VK01.C122'"
            ></div>
            <div class="number--icon">3</div>
          </div>
          <div class="body-text" [coContent]="'DL.VK01.C123'"></div>
          <button
            mat-raised-button
            class="mat-focus-indicator mat-button-bas move-on"
            (click)="next()"
            [coContent]="'DL.VK01.C124'"
            data-test-id="step-investment-btn"
          ></button>
        </div>
        <div class="container--desktop">
          <mitpfa-svg-investmentguide
            class="image--desktop"
          ></mitpfa-svg-investmentguide>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!showInvestment && showInsurance">
      <mitpfa-svg-insurance class="image--mobile--step2"></mitpfa-svg-insurance>
      <div class="wrapper">
        <div>
          <div class="headline">
            <div
              class="text--color-primary text--bold"
              [coContent]="'DL.VK01.C130'"
            ></div>
            <div class="number--icon disabled">3</div>
          </div>
          <div class="body-text" [coContent]="'DL.VK01.C131'"></div>
          <button
            mat-raised-button
            class="mat-focus-indicator mat-button-bas move-on"
            (click)="next()"
            [coContent]="'DL.VK01.C132'"
          ></button>
        </div>
        <div class="container--desktop">
          <mitpfa-svg-insurance class="image--desktop"></mitpfa-svg-insurance>
        </div>
      </div>
    </ng-container>
  </ng-template>
</mitpfa-onboarding-step-animation>
