<div class="spacing-unit--under">
  <p
    class="body--text text--manchet spacing-unit--one--half-under"
    [coContent]="'DL.VK01.C42'"
  ></p>
  <mitpfa-pension-info
    [pensionCustomer]="pensionCustomer"
    [mitIDReauthenticateMethod]="'Redirect'"
    [redirectStepName]="'SkipIntro'"
    [placement]="'registration guide'"
    [isOnboarding]="true"
    (updatePI)="continue()"
    (openedPopup)="popupOpen()"
  ></mitpfa-pension-info>
  <button
    mat-raised-button
    [coContent]="'DL.AP02.C27'"
    [disabled]="goOnDisabled"
    (click)="continue()"
    class="go-on"
    data-test-id="step-pensionsinfo-go-on-btn"
  ></button>
  <co-alert class="alert-warning spacing-unit--over" *ngIf="isUpdateError">
    <p [coContent]="'DL.VK01.C44'"></p>
  </co-alert>
</div>
