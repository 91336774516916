import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  EksternOverfoerselAnbefaling,
  ExternaltransferStore,
  MaeglerInfo,
  PensionsKundeGenerelleData,
  PensionskundeStore,
  RecommendationShortInfo
} from 'ds-api';
import { OnboardingLevelStep } from '../onboarding.model';
import {
  CombineSavingsAbility,
  CombineSavingsNavigationEvent,
  CombineSavingsSummary,
  CombineSavingsSummaryType
} from '@mitpfa/shared/combine-savings/combine-savings.model';
import { CombineSavingsService } from '../../combine-savings/combine-savings.service';
import { OnboardingService } from '../onboarding.service';
import { GuideStepOther } from '@pfa/core';

@Injectable({
  providedIn: 'root'
})
export class ExternalTransferService {
  private _recommendationShortInfo: RecommendationShortInfo;
  private _externalTransfer: EksternOverfoerselAnbefaling;
  private _pensionCustomer: PensionsKundeGenerelleData;
  private _broker: MaeglerInfo;

  private readonly externaltransferStore: ExternaltransferStore = inject(
    ExternaltransferStore
  );
  private readonly pensionskundeStore: PensionskundeStore =
    inject(PensionskundeStore);
  private readonly combineSavingsService: CombineSavingsService = inject(
    CombineSavingsService
  );
  private readonly onboardingService: OnboardingService =
    inject(OnboardingService);

  public getBroker(): Observable<MaeglerInfo> {
    return this.pensionskundeStore.pensionskundeMaeglerinfoGet().pipe(
      tap(res => {
        this._broker = res;
      })
    );
  }

  public getRecommendationShortInfo(): Observable<RecommendationShortInfo> {
    return this.externaltransferStore
      .externaltransferRecommendationShortInfoGet()
      .pipe(
        tap(res => {
          this._recommendationShortInfo = res;
        })
      );
  }

  public getExternalTransfer(): Observable<EksternOverfoerselAnbefaling> {
    return this.externaltransferStore.externaltransferGet().pipe(
      tap(res => {
        this._externalTransfer = res;
      })
    );
  }

  public getPensionCustomer(): Observable<PensionsKundeGenerelleData> {
    return this.pensionskundeStore.pensionskundeGet().pipe(
      tap(res => {
        this._pensionCustomer = res;
      })
    );
  }

  get externalTransfer(): EksternOverfoerselAnbefaling {
    return this._externalTransfer;
  }

  get broker(): MaeglerInfo {
    return this._broker;
  }

  public findNextStep(
    step: OnboardingLevelStep,
    transferredPoliciesUids: string[]
  ): OnboardingLevelStep {
    switch (step) {
      case 'EXTERNAL_TRANSFER': {
        return this.externalTransferNextStep();
      }
      case 'EXTERNAL_TRANSFER_INTRO': {
        return this.externalTransferIntroNextStep();
      }
      case 'EXTERNAL_TRANSFER_ACCEPT': {
        if (transferredPoliciesUids && transferredPoliciesUids.length > 0) {
          return 'EXTERNAL_TRANSFER_CONSENT';
        } else {
          return 'EXTERNAL_TRANSFER_SUMMARY';
        }
      }
      case 'EXTERNAL_TRANSFER_LEAD':
      case 'EXTERNAL_TRANSFER_CONSENT': {
        return 'EXTERNAL_TRANSFER_SUMMARY';
      }
      case 'EXTERNAL_TRANSFER_SUMMARY': {
        return this.shouldInvestOrInsuranceBeShown();
      }
    }
  }

  private shouldInvestOrInsuranceBeShown(): OnboardingLevelStep {
    if (
      this.onboardingService.getShowInvestment() ||
      this.onboardingService.getShowInsurance()
    ) {
      return 'STEP_INVESTMENT';
    } else {
      return 'STEP_END';
    }
  }

  private externalTransferNextStep(): OnboardingLevelStep {
    if (this.isNonTransferable()) {
      return this.shouldInvestOrInsuranceBeShown();
    } else {
      return 'EXTERNAL_TRANSFER_INTRO';
    }
  }

  private externalTransferIntroNextStep(): OnboardingLevelStep {
    const data = {
      pensionCustomer: this._pensionCustomer,
      broker: this._broker,
      receivedAgreementStatus: { modtagendeAftalegyldig: false }
    };
    this.combineSavingsService.init(data);
    if (this.combineSavingsService.allowCustomerPath(this._externalTransfer)) {
      return 'EXTERNAL_TRANSFER_ACCEPT';
    }
    if (
      this._pensionCustomer.harNavnAdresseBeskyttelse ||
      this._recommendationShortInfo.overallCategory ===
        'TRANSFERABLE_BY_ADVISOR'
    ) {
      return 'EXTERNAL_TRANSFER_LEAD';
    } else {
      return 'EXTERNAL_TRANSFER_ACCEPT';
    }
  }

  public findCombineSavingsSummaryType(
    fromStep: OnboardingLevelStep,
    combineSavingsNavigationEvent: CombineSavingsNavigationEvent
  ): CombineSavingsSummaryType {
    if (combineSavingsNavigationEvent.showErrorOnSummary ?? false) {
      return CombineSavingsSummaryType.errorOccurred;
    }
    if (
      fromStep === 'EXTERNAL_TRANSFER_ACCEPT' &&
      combineSavingsNavigationEvent.wontTransfer
    ) {
      return CombineSavingsSummaryType.policiesNotCombined;
    }

    if (fromStep === 'EXTERNAL_TRANSFER_CONSENT') {
      if (combineSavingsNavigationEvent.wontTransfer) {
        return CombineSavingsSummaryType.policiesNotCombined;
      } else {
        return CombineSavingsSummaryType.policiesCombined;
      }
    } else if (fromStep === 'EXTERNAL_TRANSFER_LEAD') {
      if (combineSavingsNavigationEvent.declineCreateLead) {
        return CombineSavingsSummaryType.policiesNotCombined;
      }
      return CombineSavingsSummaryType.policiesTransferableByAdvisor;
    } else {
      return CombineSavingsSummaryType.errorOccurred;
    }
  }

  public isNonTransferable(): boolean {
    return (
      !this._pensionCustomer.harPensionsinfo ||
      (this._pensionCustomer.harPensionsinfo &&
        this._pensionCustomer.pensionInfoDataInvalid) ||
      !this._externalTransfer?.modtagendeAftalegyldig ||
      !this._recommendationShortInfo.overallCategory ||
      this._recommendationShortInfo.overallCategory === 'NOT_TRANSFERABLE'
    );
  }

  public findGuideCompleteOther(
    combineSavingsSummary: CombineSavingsSummary
  ): GuideStepOther {
    switch (combineSavingsSummary.combineSavingsResult) {
      case CombineSavingsSummaryType.policiesCombined:
        return GuideStepOther.COMBINE_SAVINGS_TRANSFER_INITIATED;
      case CombineSavingsSummaryType.policiesNotCombined:
        if (
          this._recommendationShortInfo.overallCategory ===
          CombineSavingsAbility.TransferableByCustomer
        ) {
          return GuideStepOther.COMBINE_SAVINGS_TRANSFER_DECLINED;
        } else {
          return GuideStepOther.COMBINE_SAVINGS_ADVISORY_DECLINED;
        }
      case CombineSavingsSummaryType.policiesTransferableByAdvisor:
        return GuideStepOther.COMBINE_SAVINGS_ADVISORY;
      case CombineSavingsSummaryType.policiesNotTransferable:
        return GuideStepOther.COMBINE_SAVINGS_TRANSFER_NOT_POSSIBLE;
      default:
        return GuideStepOther.UNDEFINED;
    }
  }
}
