<div class="header">
  <div class="row">
    <div class="col-md-5 col-sm-12">
      <div
        class="header__image"
        [ngStyle]="{ 'background-image': 'url(' + imgUrl + ')' }"
      ></div>
    </div>
    <div class="col-md-5 offset-md-1 col-sm-12">
      <div class="header__content">
        <h1 [coContent]="title"></h1>
        <div class="text--manchet" [coContent]="subTitle"></div>
        <div [coContent]="text"></div>
        <div [coContent]="text2" *ngIf="text2"></div>
      </div>
    </div>
  </div>
</div>
