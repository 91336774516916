<h3 [coContent]="'DL.TR01.C107'">færdigør tilbud</h3>
<ng-container *ngIf="overview">
  <co-advisor-box
    class="advisor-approval"
    *ngIf="
      overview.policyType === 'CONTINUATION' &&
      overview.healthRequirementCode === 2
    "
  >
    <div class="text--bold" [coContent]="'DL.TR01.C491'"></div>
    <div class="spacing-over" [coContent]="'DL.TR01.C492'"></div>
    <ul>
      <li *ngFor="let text of advisorApprovalTexts" [coContent]="text"></li>
    </ul>
    <div class="spacing-over">
      <mat-checkbox [formControl]="advisorApproval">
        <span coContent="DL.TR01.C496"></span>
      </mat-checkbox>
    </div>
  </co-advisor-box>
  <co-card
    class="spacing-over"
    *ngIf="
      overview.policyType === 'CONTINUATION' ||
      isNewOfferWithReusedPolicy ||
      overview.policyType === 'EXTENDED_ON_HOLD'
    "
  >
    <div class="text--bold" [coContent]="'DL.TR01.C522'"></div>
    <div class="spacing-over">
      <mat-checkbox [formControl]="adviceAboutBeneficiary">
        <span coContent="DL.TR01.C519"></span>
      </mat-checkbox>
    </div>
  </co-card>
  <co-card *ngIf="overview.healthRequirementCode === 3">
    <div class="text--bold" [coContent]="'DL.TR01.C108'">alternativt valg</div>
    <div class="spacing-over" [coContent]="'DL.TR01.C109'"></div>

    <div class="spacing-over" [coContent]="'DL.TR01.C524'"></div>
    <mat-radio-group [formControl]="alternativesDeclined" required>
      <mat-radio-button
        *ngFor="let alternative of alternativeDeclinedOptions"
        [value]="alternative.id"
      >
        {{ alternative.label | content }}
      </mat-radio-button>
    </mat-radio-group>

    <div class="spacing-over" [coContent]="'DL.TR01.C526'"></div>
    <mat-radio-group [formControl]="alternativesPartiallyAccepted" required>
      <mat-radio-button
        *ngFor="let alternative of alternativePartiallyAcceptedOptions"
        [value]="alternative.id"
      >
        {{ alternative.label | content }}
      </mat-radio-button>
    </mat-radio-group>
  </co-card>
  <co-card>
    <div class="text--bold" [coContent]="'DL.TR01.C113'">hvidvask</div>
    <div class="spacing-over">
      <div [coContent]="'DL.TR01.C114'">formål</div>
      <div class="text--note" [coContent]="'DL.TR01.C115'">
        flere valg muligt
      </div>
      <co-checkbox-group
        [options]="purposeOptions"
        (toggle)="onPurposesChanged($event)"
      ></co-checkbox-group>
    </div>
    <div
      class="spacing-over"
      *ngIf="
        overview.companyOrPrivate === 'Privat' &&
        overview.healthInsuranceProduct !== 5 &&
        overview.policyType !== 'EXTENDED_ON_HOLD'
      "
    >
      <div [coContent]="'DL.TR01.C122'">money origin</div>
      <div class="text--note" [coContent]="'DL.TR01.C115'">
        flere valg muligt
      </div>
      <co-checkbox-group
        [options]="moneyOriginOptions"
        (toggle)="onMoneyOriginsChanged($event)"
      ></co-checkbox-group>
    </div>
  </co-card>
  <div class="spacing-unit--over">
    <button
      mat-raised-button
      class="float--left button-primary--level3 icon__on-primary--stroke"
      [routerLink]="'/advisor/new-business/overview'"
    >
      <co-icon-arrow-left class="icon icon--arrow-left"></co-icon-arrow-left>
      <span [coContent]="'Global.Tilbage'"></span>
    </button>
    <button
      mat-raised-button
      class="float--right"
      [disabled]="
        missingAdvisorApproval ||
        missingAdviceAboutBeneficiary ||
        missingAlternativeDeclined ||
        missingAlternativePartiallyAccepted ||
        missingPurpose ||
        (missingMoneyOrigin && overview.policyType !== 'EXTENDED_ON_HOLD')
      "
      (click)="onSubmit()"
      [coContent]="'DL.TR01.C131'"
    ></button>
    <div class="cf"></div>
    <co-warning *ngIf="missingAdvisorApproval">
      <span coContent="DL.TR01.C513"></span>
    </co-warning>
    <co-warning *ngIf="missingAdviceAboutBeneficiary">
      <span coContent="DL.TR01.C521"></span>
    </co-warning>
    <co-warning
      *ngIf="missingAlternativeDeclined || missingAlternativePartiallyAccepted"
    >
      <span [coContent]="'DL.TR01.C132'"></span>
    </co-warning>
    <co-warning *ngIf="missingPurpose">
      <span [coContent]="'DL.TR01.C133'"></span>
    </co-warning>
    <co-warning
      *ngIf="missingMoneyOrigin && overview.policyType !== 'EXTENDED_ON_HOLD'"
    >
      <span [coContent]="'DL.TR01.C134'"></span>
    </co-warning>
    <co-warning *ngIf="generateAgreementFailed">
      <span [coContent]="'DL.TR01.C243'"></span>
    </co-warning>
    <co-warning *ngIf="createOfferFailed">
      <span [coContent]="'DL.TR01.C141'"></span>
    </co-warning>
  </div>
</ng-container>
