<div
  class="wrapper"
  [ngClass]="{ 'multiple-elements': showBack && showJumpover }"
>
  <div>
    <button
      *ngIf="showBack"
      mat-raised-button
      class="button-primary--level3"
      (click)="back.emit()"
      data-test-id="jump-back-btn"
    >
      <co-icon-arrow-left class="icon"></co-icon-arrow-left>
      <span>{{ 'DL.VK01.C05' | content }}</span>
    </button>

    <button
      *ngIf="showJumpover"
      [disabled]="form && (!form.valid || !form.pristine)"
      mat-raised-button
      class="button-primary--level3"
      (click)="jumpover.emit()"
      data-test-id="jump-over-btn"
    >
      <span>{{ 'DL.VK01.C06' | content }}</span>
      <co-icon-arrow-right class="icon"></co-icon-arrow-right>
    </button>
  </div>
  <p *ngIf="note" class="text--note align--center" [coContent]="note"></p>
</div>
