import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  AutomaticHealthAcceptanceApiService,
  BasketApiService,
  InsuranceApiService,
  InvestmentApiService
} from '@pfa/api';
import { ContentService, PageTrackingService } from '@pfa/core';
import { MessagingService } from '@pfa/feature/messaging';
import { openClose } from '@pfa/animations';
import { combineLatest, of, timer } from 'rxjs';
import { filter, map, concatMap, switchMap } from 'rxjs/operators';
import { CombineSavingsInternalVisibilityService } from '../../combine-savings-internal/combine-savings-internal-visibility.service';
import {
  MenuItem,
  MenuItemType,
  Notifications,
  SubMenuItem
} from './menu.model';
import { MenuService } from './menu.service';

import { channel } from '@mitpfa/shared';
import {
  OnboardingStore,
  AdvancedPayoutChoiceLivnetPolicy,
  CustomerOptions,
  ExternaltransferStore,
  FordeleStore,
  IndividualcustomerprofileStore,
  PensionsKundeGenerelleData,
  PensionskundeStore,
  PfaPlanTabelData,
  SystemadministrationStore,
  VariantDetail,
  OnlineService
} from 'ds-api';
import {
  BroadcastMessageType,
  BroadcastService,
  InsuranceUtilService
} from '@pfaform';
import { PayoutPlanService } from '@mitpfa/shared/services/payout-plan.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NewBusinessAdvisoryService } from '@pfa/services';

@Component({
  selector: 'mitpfa-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [openClose]
})
export class MenuComponent implements OnInit {
  public pensionCustomer: PensionsKundeGenerelleData;
  public customerOptions: CustomerOptions;
  public notifications: Notifications;
  public showPensionCheckKillswitch = false;
  public savingsMenuItem: MenuItem;
  public advantageMenuItem: MenuItem;
  public insuranceMenuItem: MenuItem;
  public profileMenuItem: MenuItem;
  public messagesMenuItem: MenuItem;
  public newBusinessAdvisoryMenuItem: MenuItem;
  public mobileMenuItems: MenuItem[];
  public isNewBusinessAdvisory: boolean;
  private antalEksternoverfoerselIKurv: number;
  private antalAendredeOmraaderIalt: number;
  private readonly pollingDelay = 5000;
  private readonly pollingFrequency = 120000;

  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly router: Router = inject(Router);
  private readonly contentService: ContentService = inject(ContentService);
  private readonly individualcustomerprofileStore: IndividualcustomerprofileStore =
    inject(IndividualcustomerprofileStore);
  private readonly broadcastService: BroadcastService =
    inject(BroadcastService);
  public readonly advisingService: NewBusinessAdvisoryService = inject(
    NewBusinessAdvisoryService
  );
  private readonly systemadministrationStore: SystemadministrationStore =
    inject(SystemadministrationStore);
  private readonly pensionskundeStore: PensionskundeStore =
    inject(PensionskundeStore);
  private readonly combineSavingsInternalVisibilityService: CombineSavingsInternalVisibilityService =
    inject(CombineSavingsInternalVisibilityService);
  private readonly insuranceApiService: InsuranceApiService =
    inject(InsuranceApiService);
  private readonly menuService: MenuService = inject(MenuService);
  private readonly onboardingStore: OnboardingStore = inject(OnboardingStore);
  private readonly fordeleStore: FordeleStore = inject(FordeleStore);
  private readonly automaticHealthAcceptanceApiService: AutomaticHealthAcceptanceApiService =
    inject(AutomaticHealthAcceptanceApiService);
  private readonly basketApiService: BasketApiService =
    inject(BasketApiService);
  private readonly messagingService: MessagingService =
    inject(MessagingService);
  private readonly pageTrackingService: PageTrackingService =
    inject(PageTrackingService);
  private readonly payoutPlanService: PayoutPlanService =
    inject(PayoutPlanService);
  private readonly externaltransferStore: ExternaltransferStore = inject(
    ExternaltransferStore
  );
  private readonly insuranceUtilService: InsuranceUtilService =
    inject(InsuranceUtilService);
  private readonly investmentApiService: InvestmentApiService =
    inject(InvestmentApiService);

  constructor() {
    this.notifications = {
      antalAendringer: 0,
      unreadNotifications: 0,
      unreadMessages: 0
    } as Notifications;

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event: NavigationEnd) => {
        this.menuService.setSelectedMenuItem(event.url);
      });
  }

  ngOnInit(): void {
    this.savingsMenuItem = this.menuService.getSavings();
    this.advantageMenuItem = this.menuService.getAdvantages();
    this.insuranceMenuItem = this.menuService.getInsurance();
    this.profileMenuItem = this.menuService.getProfile();
    this.messagesMenuItem = this.menuService.getMessages();
    this.newBusinessAdvisoryMenuItem =
      this.menuService.getNewBusinessAdvisory();
    this.mobileMenuItems = this.menuService.getMobileMenu();

    this.menuService.setSelectedMenuItem(window.location.pathname);

    this.broadcastService.BroadcastSubject.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(message => {
      if (
        message === BroadcastMessageType.ADD_TO_BASKET ||
        message === BroadcastMessageType.EXPIRATION_DATE_UPDATED ||
        message === BroadcastMessageType.APPROVED_CHANGES ||
        message === BroadcastMessageType.DELETE_FROM_BASKET ||
        message === BroadcastMessageType.BENEFICIARY_DONE ||
        message === BroadcastMessageType.HEALTH_CASE_ADDED_TO_BASKET
      ) {
        this.getMineAendringer();
      }
    });

    this.broadcastService.BroadcastWithNumberSubject.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(data => {
      if (data.message === BroadcastMessageType.UNREAD_THREAD_MESSAGES) {
        this.notifications.unreadMessages = data.value;
        this.menuService.setQuantityOnName(
          this.messagesMenuItem,
          'BESKEDER',
          data.value
        );
      } else if (
        data.message === BroadcastMessageType.UNREAD_SERVICE_MESSAGES
      ) {
        this.notifications.unreadNotifications = data.value;
        this.menuService.setQuantityOnName(
          this.messagesMenuItem,
          'NOTIFIKATIONER',
          data.value
        );
      }
    });

    combineLatest({
      pc: this.pensionskundeStore.pensionskundeGet(),
      co: this.individualcustomerprofileStore.individualcustomerprofileCustomeroptionsGet()
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(responses => {
        this.pensionCustomer = responses.pc;

        this.customerOptions = responses.co;
        if (this.contentService.getContent()['DL.LB01.C01'] === 'lbf-on') {
          if (this.customerOptions && this.customerOptions.lbInsuranceAllowed) {
            this.menuService.showMenuItem(
              this.advantageMenuItem,
              'PARTNERSKAB'
            );
            this.menuService.showSubMenuItem(
              this.advantageMenuItem,
              'LB_FORSIKRING'
            );
          }
        }

        this.profileMenuItem.contentId = this.pensionCustomer.navn;
        this.newBusinessAdvisoryMenuItem.contentId = this.pensionCustomer.navn;
        if (
          (this.pensionCustomer?.alder < 60 && this.pensionCustomer.isPrivat) ||
          (this.pensionCustomer?.alder < 65 && this.pensionCustomer.isRaadgiver)
        ) {
          this.menuService.showSubMenuItem(
            this.savingsMenuItem,
            'OPSPARINGSPLAN'
          );
        }
      });

    this.fordeleStore
      .fordeleGet()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(data => {
        if (data?.programId) {
          this.menuService.showSubMenuItem(
            this.advantageMenuItem,
            'PERSONAL_ADVISORS'
          );
        }
      });

    this.systemadministrationStore
      .systemadministrationHentkillswitchesadminGet()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        concatMap(res => {
          if (res.killswitchDetailsPensionCheck.open) {
            this.showPensionCheckKillswitch = true;
          }

          if (res.killswitchDetailsActivateYourRecommendation.open) {
            return this.onboardingStore
              .onboardingGet()
              .pipe(takeUntilDestroyed(this.destroyRef));
          } else {
            return of();
          }
        })
      )
      .subscribe(ayr => {
        if (ayr) {
          let allButExternalTransferDone = true;

          ayr.items.forEach(item => {
            if (!item.checked && item.name !== 'EXTERNAL_TRANSFER') {
              allButExternalTransferDone = false;
            }
          });

          if (!allButExternalTransferDone) {
            this.menuService.showSubMenuItem(
              this.profileMenuItem,
              'AKTIVER_DIN_ANBEFALING'
            );
          }
        }
      });

    this.getMineAendringer();
    this.setupMineBeskederPolling();

    this.combineSavingsInternalVisibilityService.visible$.subscribe(
      (visible: boolean) => {
        if (visible) {
          this.menuService.showSubMenuItem(
            this.menuService.getSavings(),
            'KONVERTER_TIL_PFA_PLUS'
          );
        } else {
          this.menuService.hideSubMenuItem('SAVINGS', 'KONVERTER_TIL_PFA_PLUS');
        }
      }
    );

    combineLatest({
      insuranceOverview: this.insuranceApiService.getInsuranceOverviewDetails(),
      insuranceDetails: this.insuranceApiService.getInsuranceDetails(),
      pensionCustomer: this.pensionskundeStore.pensionskundeGet()
    })
      .pipe(
        concatMap(responses => {
          const policyBlocked = this.insuranceUtilService.isPolicyBlocked(
            responses.insuranceDetails.tilpasForsikringstal
          );
          const payoutInProgress = this.insuranceUtilService.payoutsInProgress(
            responses.insuranceOverview
          );
          if (
            policyBlocked ||
            (payoutInProgress && !responses.pensionCustomer.isRaadgiver)
          ) {
            this.menuService.hideSubMenuItem(
              'INSURANCE',
              'FIND_FORSKIRINGSBEHOV'
            );
          }

          // TEMP removed until SAP issues with productVariants are resolved
          /*
          const insurances = this.insuranceUtilService.mapInsurances(
            responses.insuranceOverview,
            responses.insuranceDetails,
            responses.pensionCustomer.specialCustomerRules.type_1
          );
          const healthVariantIds =
            this.insuranceUtilService.getHealthVariantIds(
              responses.insuranceOverview,
              insurances
            );
          if (healthVariantIds?.length > 0) {
            return this.insuranceApiService.productVariants(
              healthVariantIds,
              true,
              false
            );
          } else {
            return of(null);
          }
*/
          return of(null);
        }),
        switchMap((variantDetails: VariantDetail[]) => {
          if (variantDetails) {
            return of(
              this.insuranceUtilService.getOnlineVariants(variantDetails)
            );
          }
          return of(null);
        })
      )
      .subscribe({
        next: (onlineServices: OnlineService[]): void => {
          if (
            onlineServices &&
            !this.insuranceUtilService.hasOnlineServiceDoctorOnly(
              onlineServices
            )
          ) {
            this.menuService.showSubMenuItem(
              this.menuService.getInsurance(),
              'ONLINE_SUNDHEDSHJAELP'
            );
          }
        }
      });

    this.payoutPlanService
      .pfaplan()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((pfaPlan: PfaPlanTabelData) => {
        const coverages =
          this.payoutPlanService.coveragesWithPayoutsStartedIncludingCoveragesThatPayoutsAreEnded(
            pfaPlan
          );
        if (coverages && coverages.length > 0) {
          this.menuService.showSubMenuItem(
            this.savingsMenuItem,
            'UDBETALINGER'
          );
        }
      });

    this.advisingService
      .getNewBusinessAdvisory()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(res => {
        this.isNewBusinessAdvisory = res.digitalSalgRaadgivning;
      });
  }

  adobeTrackingClickPensionCheck(): void {
    this.closeTopMenu();
    this.pageTrackingService.trackNavigation(
      'Pension Check',
      'Open',
      '/handlingscenter'
    );
  }

  gotoFrontpage(): void {
    if (this.isNewBusinessAdvisory) {
      this.pageTrackingService.trackNavigation(
        'Home - New Business',
        'Frontpage - New Business',
        '/advisor/new-business/overview'
      );
      this.router.navigate(['/advisor/new-business/overview']);
    } else {
      this.pageTrackingService.trackNavigation(
        'Home',
        'Frontpage',
        '/mitoverblik'
      );
      this.router.navigate(['/mitoverblik']);
    }
  }

  closeTopMenu(): void {
    this.menuService.closeMenuItems();
  }

  openCloseMenu(menuItemType: MenuItemType): void {
    this.menuService.openCloseMenu(menuItemType);
  }

  isMenuSelected(menuItemType: MenuItemType): boolean {
    return this.menuService.isMenuSelected(menuItemType);
  }

  getSelectedMenuItem(): SubMenuItem | undefined {
    return this.menuService.getSelectedMenuItem();
  }

  getMenuItemOpen(): MenuItemType {
    return this.menuService.getMenuItemOpen();
  }

  private setupMineBeskederPolling(): void {
    timer(this.pollingDelay, this.pollingFrequency)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap(() =>
          this.messagingService.getThreadsForChannel(channel.config, false)
        )
      )
      .subscribe({
        next: result => {
          let unreadCount: number;
          if (result) {
            unreadCount = result.unreadCount ? result.unreadCount : 0;
          } else {
            unreadCount = 0;
          }
          this.notifications.unreadMessages = unreadCount;
          this.menuService.setQuantityOnName(
            this.messagesMenuItem,
            'BESKEDER',
            unreadCount
          );
        },
        error: () => {}
      });
  }

  private getMineAendringer(): void {
    combineLatest({
      basket: this.basketApiService.getBasket(
        this.contentService.getLanguage().toUpperCase()
      ),
      healthCases:
        this.automaticHealthAcceptanceApiService.getHealthAcceptanceCases(),
      external: this.externaltransferStore.externaltransferGet(),
      killSwitch:
        this.systemadministrationStore.systemadministrationHentkillswitchesadminGet()
    })
      .pipe(
        concatMap(response => {
          if (response.killSwitch?.killswitchDigitisation?.open) {
            return this.payoutPlanService.getBasketChanges().pipe(
              map(digitisationBasketResponse => ({
                ...response,
                digitisationBasket: digitisationBasketResponse
              }))
            );
          } else {
            return of({
              ...response,
              digitisationBasket: null
            });
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(results => {
        if (results.external) {
          let count = 0;
          results.external.policer?.forEach(function (police) {
            if (police.iKurv?.booleanValue) {
              count++;
            }
          });
          this.setAntalEksternoverfoerselIKurv(count);
        }

        const hasUnapprovedDigitisationBasketChanges =
          !results.digitisationBasket?.hasCoverageWithPayoutApproved &&
          results.digitisationBasket?.livnetPolicies.some(
            (policy: AdvancedPayoutChoiceLivnetPolicy) =>
              policy.advancedPayoutChoicePolicyCoverage?.length > 0 &&
              policy.advancedPayoutChoicePolicyCoverage?.some(
                coverage => coverage.startPayout
              )
          );

        const harIgangvaerendeHelbredsSkema =
          results.healthCases?.length > 0 &&
          results.healthCases[0].healthCaseStatus === 'DRAFT_MITPFA';

        const numberOfChangesInBasket =
          results.basket?.antalAendredeOmraaderIalt ?? 0;
        this.setAntalAendredeOmraaderIalt(
          numberOfChangesInBasket +
            (harIgangvaerendeHelbredsSkema ? 1 : 0) +
            (hasUnapprovedDigitisationBasketChanges ? 1 : 0)
        );
      });
  }

  private setAntalEksternoverfoerselIKurv(num: number): void {
    this.antalEksternoverfoerselIKurv = num;
    if (this.antalAendredeOmraaderIalt) {
      this.notifications.antalAendringer =
        this.antalAendredeOmraaderIalt + this.antalEksternoverfoerselIKurv;
    } else {
      this.notifications.antalAendringer = this.antalEksternoverfoerselIKurv;
    }
    this.menuService.setQuantityOnName(
      this.messagesMenuItem,
      'BESTILLINGER',
      this.notifications.antalAendringer
    );
  }

  private setAntalAendredeOmraaderIalt(num: number): void {
    this.antalAendredeOmraaderIalt = num;
    if (this.antalEksternoverfoerselIKurv) {
      this.notifications.antalAendringer =
        this.antalEksternoverfoerselIKurv + this.antalAendredeOmraaderIalt;
    } else {
      this.notifications.antalAendringer = this.antalAendredeOmraaderIalt;
    }
    this.menuService.setQuantityOnName(
      this.messagesMenuItem,
      'BESTILLINGER',
      this.notifications.antalAendringer
    );
  }

  private configInvestmentMenu(): void {
    this.investmentApiService
      .get()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: response => {
          const policies = response.detaljer.policer;
          const investment = this.savingsMenuItem.categories.find(
            category => category.name === 'INVESTERING'
          );

          if (
            investment &&
            policies.length === 1 &&
            policies[0].type !== 'Forsikring'
          ) {
            const overview = investment.items.find(
              item => item.name === 'ÆNDRE_INVESTERING'
            );

            if (overview) {
              overview.url = `/mineinvesteringer/${policies[0].id}`;
            }
          }
        },
        error: () => {}
      });
  }
}
