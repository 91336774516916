import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OekonomiDetaljer, PensionsKundeGenerelleData } from 'ds-api';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'mitpfa-pension-check-debt',
  templateUrl: './pension-check-debt.component.html',
  styleUrls: ['./pension-check-debt.component.scss']
})
export class PensionCheckDebtComponent {
  @Output() close = new EventEmitter<void>();
  @Input() financialSituationForm: UntypedFormGroup;
  @Input() financialSituation: OekonomiDetaljer;
  @Input() pensionCustomer: PensionsKundeGenerelleData;

  closeDebt() {
    this.close.emit();
  }
}
