import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { FordeleKontakt } from 'ds-api';
import { ContentService, ContentUtilService } from '@pfa/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'mitpfa-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent implements OnInit {
  @Input() contactDetails: FordeleKontakt;
  contactTypeContentId: string;
  descriptionContentId: string;
  descriptionTextForBoardContact: string;

  private readonly contentUtilService: ContentUtilService =
    inject(ContentUtilService);
  private readonly contentService: ContentService = inject(ContentService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.contactTypeContentId = this.getContactType();
    this.descriptionContentId = this.getDescription();

    this.contentService.languageState
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        () =>
          (this.descriptionTextForBoardContact =
            this.getBoardMemeberDescriptionText())
      );
  }

  private getBoardMemeberDescriptionText(): string {
    const personalPronoun: string = this.contentUtilService.getContent(
      this.isFemale() ? 'DL.MF.C31' : 'DL.MF.C32'
    );
    return this.contentUtilService.getContent('DL.MF.C15', [
      this.contactDetails.name,
      personalPronoun
    ]);
  }

  private isFemale(): boolean {
    const femaleNamesToCheck: string =
      this.contentUtilService.getContent('DL.MF.C33');
    const femaleNamesToCheckList: string[] = femaleNamesToCheck?.split(',');
    return femaleNamesToCheckList.some(name =>
      this.contactDetails.name.startsWith(name)
    );
  }

  private getContactType(): string {
    switch (this.contactDetails.type) {
      case 'Pension':
        return 'DL.MF.C02';
      case 'Direktion':
        return 'DL.MF.C04';
      case 'Investering':
        return 'DL.MF.C06';
      case 'Jura':
        return 'DL.MF.C07';
      case 'Sundhed':
        return 'DL.MF.C08';
      case 'Privatlaege':
        return 'DL.MF.C09';
    }
  }

  private getDescription(): string {
    switch (this.contactDetails.type) {
      case 'Pension':
        return 'DL.MF.C14';
      case 'Investering':
        return 'DL.MF.C16';
      case 'Jura':
        return 'DL.MF.C17';
      case 'Sundhed':
        return 'DL.MF.C18';
      case 'Privatlaege':
        return 'DL.MF.C19';
    }
    return '';
  }
}
