import {
  Component,
  OnInit,
  OnDestroy,
  inject,
  Output,
  EventEmitter
} from '@angular/core';
import { combineLatest, of, Subject } from 'rxjs';
import { InsuranceApiService } from '@pfa/api';
import { switchMap } from 'rxjs/operators';
import { InsuranceListItem } from './dashboard-insurance.model';
import {
  ContentService,
  ContentUtilService,
  MiscTrackingService
} from '@pfa/core';
import { Router } from '@angular/router';
import { InsuranceUtilService } from '@pfaform';
import {
  ForsikringDetaljer,
  Forsikringsoversigt,
  OnlineService,
  PensionsKundeGenerelleData,
  PensionskundeStore,
  SpecialCustomerRules
} from 'ds-api';
import { Insurances } from '@pfa/models';

@Component({
  selector: 'mitpfa-dashboard-insurance',
  templateUrl: './dashboard-insurance.component.html',
  styleUrls: ['./dashboard-insurance.component.scss']
})
export class DashboardInsuranceComponent implements OnInit, OnDestroy {
  private readonly insuranceApiService: InsuranceApiService =
    inject(InsuranceApiService);
  private readonly insuranceUtilService: InsuranceUtilService =
    inject(InsuranceUtilService);
  private readonly pensionskundeStore: PensionskundeStore =
    inject(PensionskundeStore);
  private readonly contentService: ContentService = inject(ContentService);
  private readonly contentUtilService: ContentUtilService =
    inject(ContentUtilService);
  private readonly trackingService: MiscTrackingService =
    inject(MiscTrackingService);
  private readonly router: Router = inject(Router);

  @Output() contentLoaded = new EventEmitter<void>();

  public insurances: Insurances;
  public insuranceList: Array<InsuranceListItem>;
  public initialized: boolean;
  public policiesWithError: boolean;
  private unsubscribe = new Subject<void>();
  private healthHeader: string;
  private healthHeaderNote: string;
  private criticalIllnessHeaderNote: string;
  private lossOfOccupationalCapacityHeaderNote: string;
  private specialCustomerRules: SpecialCustomerRules;
  private onlineServices: Array<OnlineService>;

  public ngOnInit(): void {
    let data = {
      insuranceOverview: {} as Forsikringsoversigt,
      insuranceDetails: {} as ForsikringDetaljer,
      pensionCustomer: {} as PensionsKundeGenerelleData
    };

    combineLatest({
      insuranceOverview: this.insuranceApiService.getInsuranceOverviewDetails(),
      insuranceDetails: this.insuranceApiService.getInsuranceDetails(),
      pensionCustomer: this.pensionskundeStore.pensionskundeGet()
    })
      .pipe(
        switchMap(responses => {
          data = responses;
          this.policiesWithError =
            data.insuranceOverview.fejledePolicenumre.length > 0;
          this.insurances = this.insuranceUtilService.mapInsurances(
            data.insuranceOverview,
            data.insuranceDetails,
            data.pensionCustomer.specialCustomerRules.type_1
          );
          this.specialCustomerRules = data.pensionCustomer.specialCustomerRules;

          return of(null);
        }),
        switchMap(variantDetails => {
          if (variantDetails) {
            this.onlineServices =
              this.insuranceUtilService.getOnlineVariants(variantDetails);
          }

          return this.contentService.languageState;
        })
      )
      .subscribe({
        next: () => {
          this.createHeaderNotes();
          this.createInsuranceList(data.insuranceOverview, this.insurances);
          this.initialized = true;
          this.contentLoaded.emit();
        },
        error: () => {
          this.policiesWithError = true;
          this.initialized = true;
          this.contentLoaded.emit();
        }
      });
  }

  private createHeaderNotes(): void {
    this.healthHeader = this.specialCustomerRules.type_1
      ? 'DL.FG02.C18'
      : 'DL.FG02.C06';
    this.healthHeaderNote = this.insuranceUtilService.mapHealthNoteText(
      this.specialCustomerRules,
      this.insurances
    );
    this.criticalIllnessHeaderNote =
      this.insuranceUtilService.mapCriticalIllnessHeaderNoteText(
        this.insurances
      );
    this.lossOfOccupationalCapacityHeaderNote =
      this.insuranceUtilService.mapLossOfOccupationalCapacityHeaderNoteText(
        this.insurances
      );
  }

  private createInsuranceList(
    insuranceOverview: Forsikringsoversigt,
    insurances: Insurances
  ) {
    this.insuranceList = new Array<InsuranceListItem>();

    // TEMP removed until SAP issues with productVariants are resolved
    /*
    if (this.onlineServices?.length > 0) {
      if (
        this.insuranceUtilService.hasOnlineServiceDoctorOnly(
          this.onlineServices
        )
      ) {
        this.addInsurance(
          true,
          'DL.FG02.C357',
          'DL.FG02.C356',
          'onlinedoctor',
          'onlinedoctor'
        );
      } else {
        this.addInsurance(
          true,
          'DL.FG02.C379',
          this.insuranceUtilService.getOnlineServiceNoteText(
            this.onlineServices
          ),
          'onlineservices',
          'onlineservices'
        );
      }
    } else {
      this.addInsurance(
        this.insurances.onlineDoctor,
        'DL.FG02.C357',
        'DL.FG02.C356',
        'onlinedoctor',
        'onlinedoctor'
      );
    }
    */

    this.addInsurance(
      this.insurances.mentalWellbeing,
      'DL.FG02.C21',
      'DL.FG02.C22',
      'mentalwellbeing',
      'mentalWellbeing'
    );
    this.addInsurance(
      this.insurances.abuseHotline,
      'DL.FG02.C24',
      'DL.FG02.C25',
      'abusehotline',
      'abuseHotline'
    );
    this.addInsurance(
      this.insurances.pfaHealth || this.insurances.pfaHealthLetpension,
      this.healthHeader,
      this.healthHeaderNote,
      'healthinsurance',
      'pfaHealth'
    );

    this.addInsurance(
      this.insurances.diagnosis,
      'DL.FG02.C27',
      'DL.FG02.C28',
      'pfadiagnosis',
      'diagnosis'
    );
    this.addInsurance(
      this.insurances.health,
      'DL.FG02.C30',
      'DL.FG02.C31',
      'pfahealthinsurance',
      'health'
    );
    this.addInsurance(
      this.insurances.earlyCare,
      'DL.FG02.C15',
      'DL.FG02.C16',
      'pfaearlyCare',
      'earlyCare'
    );

    this.addInsurance(
      this.insurances.healthHotline,
      'DL.FG02.C12',
      'DL.FG02.C13',
      'pfahealthcarehotline',
      'healthHotline'
    );
    this.addInsurance(
      this.insurances.preventiveCare,
      'DL.FG02.C08',
      'DL.FG02.C09',
      'pfapreventivecare',
      'preventiveCare'
    );
    this.addInsurance(
      this.insurances.criticalIllnessPfa ||
        this.insurances.criticalIllnessLetpension,
      'DL.FG02.C35',
      this.criticalIllnessHeaderNote,
      'criticalillness',
      'criticalIllness'
    );
    this.addInsurance(
      this.insurances.lossOfOccupationalCapacityPfa ||
        this.insurances.lossOfOccupationalCapacityLetpension,
      'DL.FG02.C38',
      this.lossOfOccupationalCapacityHeaderNote,
      'lossofoccupationalcapacity',
      'lossOfOccupationalCapacity'
    );
    this.addInsurance(
      this.insurances.life,
      'DL.FG02.C41',
      'DL.FG02.C42',
      'lifeinsurance',
      'life'
    );
    this.addInsurance(
      this.insurances.crossLife,
      'DL.FG02.C44',
      'DL.FG02.C45',
      'crosslife',
      'crossLife'
    );
  }

  private addInsurance(
    show: boolean,
    headerContent: string,
    headerNoteContent: string,
    navigationAction: string,
    testId: string,
    link?: string
  ): void {
    if (show) {
      this.insuranceList.push(
        new InsuranceListItem(
          this.contentUtilService.getContent(headerContent),
          this.contentUtilService.getContent(headerNoteContent),
          navigationAction,
          testId,
          link
        )
      );
    }
  }

  public goToInsurance(navigationAction: string): void {
    this.trackingService.trackNavigationCard(
      'Insurance section',
      navigationAction,
      '/mineforsikringer'
    );
    if (navigationAction === 'onlineservices') {
      this.router.navigate(['/mineforsikringer/onlinesundhed']);
    } else {
      this.router.navigate(['/mineforsikringer']);
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
