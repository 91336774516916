import { Component, Inject, inject, OnInit } from '@angular/core';
import { ErrorPageInfo } from 'ds-api';
import { Router } from '@angular/router';
import { SharedErrorHandler } from '@pfa/feature/error';
import { DomainService } from './domain.service';

@Component({
  selector: 'co-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  public dateTime: string;
  public errorDump: ErrorPageInfo;
  public showErrorDump: boolean;

  private readonly errorHandler: SharedErrorHandler =
    inject(SharedErrorHandler);
  private readonly domainService: DomainService = inject(DomainService);
  private readonly router: Router = inject(Router);

  ngOnInit(): void {
    // if we are browsing from an internal domain, then show error details to the user
    this.showErrorDump = !this.domainService.isExternalDomain();
    if (this.showErrorDump) {
      this.errorDump = this.errorHandler.lastError;
    }

    this.dateTime = this.getDateTimeNowString('en-GB');
  }

  logout() {
    this.router.navigate(['/logout']);
  }

  refresh(): void {
    location.reload();
  }

  private getDateTimeNowString(locale: string): string {
    const now = new Date();
    return `${now.toLocaleDateString(locale)} ${now.toLocaleTimeString(
      locale
    )}`;
  }
}
