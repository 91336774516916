import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  inject,
  DestroyRef
} from '@angular/core';
import { MiscTrackingService } from '@pfa/core';
import { Router } from '@angular/router';
import { OpsparingOversigt, SavingsStore } from 'ds-api';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'mitpfa-dashboard-savings-pension-in',
  templateUrl: './dashboard-savings-pension-in.component.html',
  styleUrls: ['./dashboard-savings-pension-in.component.scss']
})
export class DashboardSavingsPensionInComponent implements OnInit {
  private readonly savingsStore: SavingsStore = inject(SavingsStore);
  private readonly trackingService: MiscTrackingService =
    inject(MiscTrackingService);
  private readonly router: Router = inject(Router);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  @Output() contentLoaded = new EventEmitter<void>();

  public savings: OpsparingOversigt;
  public savingsServiceError: boolean;
  public savingsInitialized: boolean;
  public atLeastOnePoliceWithoutSavings: boolean;
  public policiesWithError: boolean;
  public allPoliciesWithError: boolean;

  constructor() {
    this.savingsServiceError = false;
    this.atLeastOnePoliceWithoutSavings = false;
    this.savingsInitialized = false;
  }

  public ngOnInit(): void {
    this.savingsStore
      .opsparingGet()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: savings => {
          if (savings) {
            this.savings = savings;
            this.policiesWithError = this.savings.fejledeAftaler?.length > 0;
            this.allPoliciesWithError =
              this.savings.policer.length !== 0 &&
              this.savings.policer.length ===
                this.savings.fejledeAftaler?.length;
          }
          this.savingsInitialized = true;
          this.contentLoaded.emit();
        },
        error: () => {
          this.savingsServiceError = true;
          this.savingsInitialized = true;
          this.contentLoaded.emit();
        }
      });
  }

  goToMySavings() {
    this.trackingService.trackNavigationCard(
      'Savings section',
      'Pension savings in total',
      '/minopsparing'
    );
    this.router.navigate(['/minopsparing']);
  }
}
