import { Component, inject, OnInit } from '@angular/core';
import {
  ByggeKmdlHtmlKontraktSvar,
  ByggeSimulationKmdlHtmlKontaktRequest,
  CreateOfferReq,
  TilbudOverblik
} from 'ds-api';
import { ContentUtilService } from '@pfa/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  LabelInputComponent,
  LabelInputComponentData
} from './label-input/label-input.component';
import { CheckboxItem, GlobalWorkingService } from '@pfaform';
import { ShowAgreementDialogComponent } from './show-agreement/show-agreement-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ReleaseReceiptDialogComponent } from './release-receipt-dialog/release-receipt-dialog.component';
import { NewBusinessAdvisoryService } from '@pfa/services';

@Component({
  selector: 'mitpfa-release-offer',
  templateUrl: './release-offer.component.html',
  styleUrls: ['./release-offer.component.scss']
})
export class ReleaseOfferComponent implements OnInit {
  private readonly contentUtilService: ContentUtilService =
    inject(ContentUtilService);
  private readonly globalWorking: GlobalWorkingService =
    inject(GlobalWorkingService);
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly advisingService: NewBusinessAdvisoryService = inject(
    NewBusinessAdvisoryService
  );

  overview: TilbudOverblik;

  readonly alternativeDeclinedOptions: IdLabel[] = [
    new IdLabel('SavingsOnly', 'DL.TR01.C525'), // oprette en pensionsordning med rent opsparing uden indbetalingssikring
    new IdLabel('CancelOffer', 'DL.TR01.C112') // annuller
  ];

  readonly alternativePartiallyAcceptedOptions: IdLabel[] = [
    new IdLabel('PartialHealth', 'DL.TR01.C527'), // oprette en pensionsordning med de forsikringsdækninger jeg kan få
    new IdLabel('CancelOffer', 'DL.TR01.C112') // annuller
  ];

  private readonly purposeComment = new LabelInputComponentData(
    this.contentUtilService.getContent('DL.TR01.C120'),
    this.contentUtilService.getContent('DL.TR01.C121')
  );
  readonly purposeOptions: CheckboxItem[] = [
    CheckboxItem.factory(
      '01',
      this.contentUtilService.getContent('DL.TR01.C116')
    ), // Langsigtet opsparing til pensionering
    CheckboxItem.factory(
      '02',
      this.contentUtilService.getContent('DL.TR01.C117')
    ), // Kortsigtet opsparing til investering
    CheckboxItem.factory(
      '03',
      this.contentUtilService.getContent('DL.TR01.C118')
    ), // Forsikringer ved død
    CheckboxItem.factory(
      '04',
      this.contentUtilService.getContent('DL.TR01.C119')
    ), // Forsikringer ved sygdom
    new CheckboxItem('05', LabelInputComponent, this.purposeComment) // Andet
  ];

  private readonly moneyOriginComment = new LabelInputComponentData(
    this.contentUtilService.getContent('DL.TR01.C120'),
    this.contentUtilService.getContent('DL.TR01.C121')
  );
  readonly moneyOriginOptions: CheckboxItem[] = [
    CheckboxItem.factory(
      '01',
      this.contentUtilService.getContent('DL.TR01.C123')
    ), // Løn fra arbejdsgiver
    CheckboxItem.factory(
      '02',
      this.contentUtilService.getContent('DL.TR01.C124')
    ), // Løn fra egen virksomhed
    CheckboxItem.factory(
      '03',
      this.contentUtilService.getContent('DL.TR01.C125')
    ), // Overskud fra egen virksomhed
    CheckboxItem.factory(
      '04',
      this.contentUtilService.getContent('DL.TR01.C126')
    ), // Offentlige ydelser
    CheckboxItem.factory(
      '05',
      this.contentUtilService.getContent('DL.TR01.C127')
    ), // Pension
    CheckboxItem.factory(
      '06',
      this.contentUtilService.getContent('DL.TR01.C128')
    ), // Salg af bolig eller virksomhed
    CheckboxItem.factory(
      '07',
      this.contentUtilService.getContent('DL.TR01.C129')
    ), // Arv
    CheckboxItem.factory(
      '08',
      this.contentUtilService.getContent('DL.TR01.C130')
    ), // Frie midler
    new CheckboxItem('09', LabelInputComponent, this.moneyOriginComment) // Andet
  ];

  readonly advisorApprovalTexts = [
    'DL.TR01.C493',
    'DL.TR01.C494',
    'DL.TR01.C495'
  ];

  form = new UntypedFormGroup({
    advisorApproval: new UntypedFormControl(false),
    adviceAboutBeneficiary: new UntypedFormControl(false),
    alternativesDeclined: new UntypedFormControl(),
    alternativesPartiallyAccepted: new UntypedFormControl()
  });
  private selectedPurposes: string[] = [];
  private selectedMoneyOrigins: string[] = [];
  private purposeCommentListener = false;
  private moneyOriginCommentListener = false;

  generateAgreementFailed = false;
  createOfferFailed = false;
  missingPurpose = false;
  missingMoneyOrigin = false;
  missingAlternativeDeclined = false;
  missingAlternativePartiallyAccepted = false;
  missingAdvisorApproval = false;
  missingAdviceAboutBeneficiary = false;
  isNewOfferWithReusedPolicy: boolean;

  get alternativesDeclined(): UntypedFormControl {
    return this.form.get('alternativesDeclined') as UntypedFormControl;
  }

  get alternativesPartiallyAccepted(): UntypedFormControl {
    return this.form.get('alternativesPartiallyAccepted') as UntypedFormControl;
  }

  get advisorApproval(): UntypedFormControl {
    return this.form.get('advisorApproval') as UntypedFormControl;
  }

  get adviceAboutBeneficiary(): UntypedFormControl {
    return this.form.get('adviceAboutBeneficiary') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.overview = history.state.data;

    this.missingAlternativeDeclined = [3].includes(
      this.overview.healthRequirementCode
    );
    this.missingAlternativePartiallyAccepted = [3].includes(
      this.overview.healthRequirementCode
    );
    this.missingPurpose = true;
    this.missingMoneyOrigin =
      this.overview.companyOrPrivate === 'Privat' &&
      this.overview.healthInsuranceProduct !== 5;
    this.missingAdvisorApproval =
      this.overview.policyType === 'CONTINUATION' &&
      this.overview.healthRequirementCode === 2;
    this.isNewOfferWithReusedPolicy =
      this.overview.policyType === 'NEW' && this.overview.policyNumberReused;
    this.missingAdviceAboutBeneficiary =
      this.overview.policyType === 'CONTINUATION' ||
      this.isNewOfferWithReusedPolicy;

    this.alternativesDeclined.valueChanges.subscribe(newValue => {
      this.missingAlternativeDeclined = !newValue;
    });

    this.alternativesPartiallyAccepted.valueChanges.subscribe(newValue => {
      this.missingAlternativePartiallyAccepted = !newValue;
    });

    this.advisorApproval.valueChanges.subscribe(newValue => {
      this.missingAdvisorApproval = !newValue;
    });

    this.adviceAboutBeneficiary.valueChanges.subscribe(newValue => {
      this.missingAdviceAboutBeneficiary = !newValue;
    });
  }

  onPurposesChanged(purposes: string[]) {
    this.selectedPurposes = purposes;
    this.purposeComment.componentRef.instance.selected =
      purposes.includes('05');
    this.missingPurpose =
      this.selectedPurposes.length === 0 ||
      (purposes.includes('05') &&
        !this.purposeComment.componentRef.instance.comment.value);
    if (!this.purposeCommentListener) {
      this.purposeCommentListener = true;
      this.purposeComment.componentRef.instance.comment.valueChanges.subscribe(
        newValue => {
          this.missingPurpose = !newValue;
        }
      );
    }
  }

  onMoneyOriginsChanged(moneyOrigins: string[]) {
    this.selectedMoneyOrigins = moneyOrigins;
    this.moneyOriginComment.componentRef.instance.selected =
      moneyOrigins.includes('09');
    this.missingMoneyOrigin =
      this.selectedMoneyOrigins.length === 0 ||
      (moneyOrigins.includes('09') &&
        !this.moneyOriginComment.componentRef.instance.comment.value);
    if (!this.moneyOriginCommentListener) {
      this.moneyOriginCommentListener = true;
      this.moneyOriginComment.componentRef.instance.comment.valueChanges.subscribe(
        newValue => {
          this.missingMoneyOrigin = !newValue;
        }
      );
    }
  }

  onSubmit() {
    const originOfMoneyComment = this.moneyOriginComment.componentRef
      ? this.moneyOriginComment.componentRef.instance.comment.value
      : '';
    const request: ByggeSimulationKmdlHtmlKontaktRequest = {
      serviceInfo: undefined,
      alternativePolicyChoice: undefined,
      alternativePolicyChoiceDeclined: this.alternativesDeclined.value,
      alternativePolicyChoicePartiallyAccepted:
        this.alternativesPartiallyAccepted.value,
      originOfMoneyComment: originOfMoneyComment,
      originOfMoneySelectedList: {
        originOfMoneyCodes: this.selectedMoneyOrigins
      },
      purposeOfPolicyComment:
        this.purposeComment.componentRef.instance.comment.value,
      purposeOfPolicySelectedList: { purposeCodes: this.selectedPurposes },
      sprog: 'DA'
    };

    this.generateAgreementFailed = false;
    this.createOfferFailed = false;
    this.globalWorking.show();
    this.generateAgreementFailed = false;
    this.advisingService.buildAgreement(request).subscribe(
      response => {
        this.globalWorking.hide();
        this.generateAgreementFailed = response.fejl;
        if (!response.fejl) {
          this.showAgreement(response);
        }
      },
      () => {
        this.globalWorking.hide();
        this.generateAgreementFailed = true;
      }
    );
  }

  private showAgreement(agreement: ByggeKmdlHtmlKontraktSvar) {
    const modalRef = this.dialog.open(ShowAgreementDialogComponent, {
      minWidth: '90vw',
      height: '90vh'
    });
    const dialog = modalRef.componentInstance;
    dialog.agreement = agreement.html;
    modalRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        const originOfMoneyComment = this.moneyOriginComment.componentRef
          ? this.moneyOriginComment.componentRef.instance.comment.value
          : '';
        const request: CreateOfferReq = {
          quotationGuid: this.overview.quotationGuid,
          businessContactElement: undefined,
          monthlyPaymentAmount: this.overview.totalIndbetalingBeloeb,
          monthlyPaymentPercentage: this.overview.totalIndbetalingPct,
          alternativePolicyChoice: undefined,
          alternativePolicyChoiceDeclined: this.alternativesDeclined.value,
          alternativePolicyChoicePartiallyAccepted:
            this.alternativesPartiallyAccepted.value,
          originOfMoneyComment: originOfMoneyComment,
          originOfMoneySelectedList: {
            originOfMoneyCodes: this.selectedMoneyOrigins
          },
          purposeOfPolicyComment:
            this.purposeComment.componentRef.instance.comment.value,
          purposeOfPolicySelectedList: { purposeCodes: this.selectedPurposes }
        };
        this.globalWorking.show();
        this.advisingService.createOffer(request).subscribe(
          () => {
            this.globalWorking.hide();
            this.notifyProfileUpdated();
            this.showReleaseReceipt();
          },
          () => {
            this.globalWorking.hide();
            this.createOfferFailed = true;
          }
        );
      }
    });
  }

  private notifyProfileUpdated() {
    const currentValue = window.localStorage.getItem('updateCustomerProfile');
    window.localStorage.setItem(
      'updateCustomerProfile',
      currentValue ? '' : '1'
    );
  }

  private showReleaseReceipt() {
    const modalRef = this.dialog.open(ReleaseReceiptDialogComponent);
    modalRef.afterClosed().subscribe(() => {
      window.close();
    });
  }
}

class IdLabel {
  id: string;
  label: string;
  constructor(id: string, label: string) {
    this.id = id;
    this.label = label;
  }
}
