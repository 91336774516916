import { DigitalSalgType } from 'ds-api';
import { AdvisorMenuSection } from './advisor-menu.model';

export const AdvisorMenuSections = [
  {
    name: 'Dine oplysninger',
    contentId: 'DL.RM01.C09',
    children: [
      {
        name: 'Familie',
        contentId: 'DL.TR01.C02',
        selected: false,
        url: '/mine-oplysninger/familie'
      },
      {
        name: 'Pension',
        contentId: 'DL.RM01.C01',
        url: '/mine-oplysninger/pensionsinfo'
      },
      {
        name: 'Oekonomi',
        contentId: 'DL.TR01.C04',
        url: '/mine-oplysninger/oekonomi'
      },
      {
        name: 'Kontaktoplysninger',
        contentId: 'DL.TR01.C05',
        url: '/mine-oplysninger'
      }
    ]
  },
  {
    name: 'Opsparing',
    contentId: 'DL.RM01.C02',
    children: [
      {
        name: 'Formueplan',
        contentId: 'DL.RM01.C03',
        url: '/pensionsplan/formueoverblik'
      },
      {
        name: 'Opsparingsplan',
        contentId: 'DL.RM01.C11',
        url: '/pensionstal',
        hide: true
      },
      {
        name: 'Udbetalingsplan',
        contentId: 'DL.RM01.C12',
        url: '/pensionsplan/minudbetalingsplan'
      }
    ]
  },
  {
    name: 'Investering',
    contentId: 'DL.TR01.C49',
    children: [
      {
        name: 'Investment Guide',
        contentId: 'DL.INVGU01.C01',
        url: '/mineinvesteringer/guide'
      },
      {
        name: 'Investment overview',
        contentId: 'DL.RM01.C05',
        url: '/mineinvesteringer'
      }
    ]
  },
  {
    name: 'Forsikring',
    contentId: 'DL.TR01.C75',
    children: [
      {
        name: 'Your insurance',
        contentId: 'DL.RM01.C06',
        url: '/mineforsikringer'
      },
      {
        name: 'Insurance guide',
        contentId: 'DL.TR01.C10',
        url: '/mineforsikringer/forsikringsguide'
      },
      {
        name: 'Tilpas forsikringer',
        contentId: 'DL.TR01.C12',
        url: '/mineforsikringer/tilpas'
      }
    ]
  },
  {
    name: 'InheritanceAndBeneficiary',
    contentId: 'DL.HE01.C139',
    children: [
      {
        name: 'InheritanceAndBeneficiary',
        contentId: 'DL.HE01.C139',
        url: '/mineforsikringer/arvogbegunstigelse'
      }
    ]
  },
  {
    name: 'Closing',
    contentId: 'DL.RM01.C07',
    children: [
      {
        name: 'Closing',
        contentId: 'DL.RM01.C07',
        url: '/mineaendringer'
      }
    ]
  }
];

export const getNewBusinessSections = (
  digitalSalgPoliceNr: number,
  hideInvestment: boolean
): AdvisorMenuSection[] =>
  [
    {
      name: 'Dine oplysninger',
      contentId: 'DL.RM01.C09',
      children: [
        {
          name: 'Familie',
          contentId: 'DL.TR01.C02',
          selected: false,
          url: '/mine-oplysninger/familie'
        },
        {
          name: 'Pension',
          contentId: 'DL.TR01.C03',
          visited: false,
          url: '/mine-oplysninger/pensionsinfo'
        },
        {
          name: 'oekonomi',
          contentId: 'DL.TR01.C04',
          url: '/mine-oplysninger/oekonomi'
        },
        {
          name: 'Kontaktoplysninger',
          contentId: 'DL.TR01.C05',
          url: '/mine-oplysninger'
        },
        {
          name: 'Samtykker',
          contentId: 'DL.TR01.C06',
          url: '/mine-oplysninger/samtykker'
        }
      ]
    },
    {
      name: 'Investering',
      contentId: 'DL.TR01.C49',
      hide: hideInvestment,
      children: [
        {
          name: 'Find investeringsplan',
          contentId: 'DL.TR01.C07',
          url: '/mineinvesteringer/guide'
        },
        {
          name: 'Tilpas investering',
          contentId: 'DL.TR01.C08',
          url: `/mineinvesteringer/${digitalSalgPoliceNr}`
        },
        {
          name: 'Tilpas kundekapital',
          contentId: 'DL.TR01.C104',
          url: `/kundekapital/${digitalSalgPoliceNr}`
        }
      ]
    },
    {
      name: 'Planlaeg din pension',
      contentId: 'DL.TR01.C09',
      children: [
        {
          name: 'Opsparingsplan',
          contentId: 'DL.MN01.C32',
          url: '/pensionstal'
        },
        {
          name: 'Udbetalingsplan',
          contentId: 'DL.MN01.C34',
          url: '/pensionsplan/minudbetalingsplan'
        }
      ]
    },
    {
      name: 'Forsikring',
      contentId: 'DL.RM01.C010',
      children: [
        {
          name: 'Find forsikringsbehov',
          contentId: 'DL.TR01.C10',
          url: '/mineforsikringer/forsikringsguide'
        },
        {
          name: 'Forsikringsoversigt',
          contentId: 'DL.TR01.C11',
          url: '/mineforsikringer'
        },
        {
          name: 'Tilpas forsikring',
          contentId: 'DL.TR01.C12',
          url: '/mineforsikringer/tilpas'
        }
      ]
    },
    {
      name: 'New business overview',
      contentId: 'DL.TR01.C13',
      children: [
        {
          name: 'New business overview',
          contentId: 'DL.TR01.C13',
          url: '/advisor/new-business/overview',
          noIcon: true
        }
      ]
    }
  ].map((menuSection: AdvisorMenuSection) => {
    menuSection.children = menuSection.children.filter(item => !item.hide);
    return menuSection;
  });
