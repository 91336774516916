import {
  Component,
  DestroyRef,
  OnInit,
  ViewChild,
  inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BookingApiService } from '@pfa/api';
import { ContentService, PopupTrackingService } from '@pfa/core';
import {
  CustomerContactDetailsComponent,
  GlobalWorkingService,
  SaveLanguageModalComponent,
  UnsavedChangesWarningComponent
} from '@pfaform';
import { PopupStyle } from '@pfa/models';
import { PensionsKundeGenerelleData, PensionskundeStore } from 'ds-api';

const popupName = 'contact information: book meeting';

@Component({
  selector: 'mitpfa-booking-portal',
  templateUrl: './booking-portal.component.html',
  styleUrls: ['./booking-portal.component.scss']
})
export class BookingPortalComponent implements OnInit {
  private readonly contentService: ContentService = inject(ContentService);
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly router: Router = inject(Router);
  private readonly bookingApiService: BookingApiService =
    inject(BookingApiService);
  private readonly trackingService: PopupTrackingService =
    inject(PopupTrackingService);
  private readonly pensionskundeStore: PensionskundeStore =
    inject(PensionskundeStore);
  private readonly globalWorkingService: GlobalWorkingService =
    inject(GlobalWorkingService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  @ViewChild(CustomerContactDetailsComponent)
  contactComponent!: CustomerContactDetailsComponent;

  public slideToggleLanguageChecked: boolean;
  public showError: boolean;
  public dataLoaded: boolean;
  public pensionCustomer: PensionsKundeGenerelleData;
  public showPopupBlockNote: boolean;
  public skedifyLink: string;
  public disableSkedifyRedirect: boolean = true;

  public ngOnInit(): void {
    this.contentService.languageState
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(language => {
        this.slideToggleLanguageChecked = language !== 'da';
      });

    this.pensionskundeStore
      .pensionskundeGet()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: data => {
          this.pensionCustomer = data;
          this.trackingService.trackPopupViewed(popupName);
          this.dataLoaded = true;
        },
        error: () => {
          this.showError = true;
          this.dataLoaded = true;
        }
      });
  }

  public close(): void {
    if (
      this.contactComponent &&
      this.contactComponent.contactDetailsForm.dirty
    ) {
      this.dialog
        .open(UnsavedChangesWarningComponent)
        .afterClosed()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(response => {
          if (response.result === 'ACCEPT') {
            this.redirectToDashboard();
          }
        });
    } else {
      this.redirectToDashboard();
    }
  }

  public setSkedifyLink(): void {
    this.globalWorkingService.show();

    this.bookingApiService
      .requestBooking()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: data => {
          this.globalWorkingService.hide();
          if (data.bookingContext && data.bookingContext.selfServicingUrl) {
            this.skedifyLink = data.bookingContext.selfServicingUrl;
            this.disableSkedifyRedirect = false;
          } else {
            this.showError = true;
            this.disableSkedifyRedirect = true;
          }
        },
        error: () => {
          this.showError = true;
          this.disableSkedifyRedirect = true;
          this.globalWorkingService.hide();
        }
      });
  }

  public redirectToSkedify(): void {
    this.trackingService.trackPopupCallToAction(popupName);
    window.open(this.skedifyLink, '_blank');
    this.showPopupBlockNote = true;
  }

  public changeLanguage(event): void {
    const language = event.checked ? 'en' : 'da';

    this.slideToggleLanguageChecked = event.checked;
    this.contentService.setLanguage(language);
    this.contentService
      .loadLanguage()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(res => {
        if (res !== language.toUpperCase()) {
          this.dialog.open(SaveLanguageModalComponent, {
            disableClose: true,
            panelClass: PopupStyle.PopupSmall
          });
        }
      });
  }

  public checkFormChange(contactDetailsForm): void {
    contactDetailsForm.controls.mobil.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.disableSkedifyRedirect = true;
      });

    contactDetailsForm.controls.kundeEmail.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.disableSkedifyRedirect = true;
      });
  }

  private redirectToDashboard(): void {
    this.trackingService.trackPopupClosed(popupName);
    this.router.navigateByUrl('/');
  }
}
