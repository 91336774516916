import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AarsagTilBrug, BrugerStore, PensionsKundeGenerelleData } from 'ds-api';
import { GlobalWorkingService } from '@pfaform';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'mitpfa-advisor-warning-modal',
  templateUrl: './advisor-warning-modal.component.html'
})
export class AdvisorWarningModalComponent {
  constructor(
    private readonly dialog: MatDialogRef<AdvisorWarningModalComponent>,
    private readonly brugerStore: BrugerStore,
    @Inject(MAT_DIALOG_DATA)
    public readonly pensionCustomer: PensionsKundeGenerelleData,
    private readonly globalWorkingService: GlobalWorkingService
  ) {
    dialog.disableClose = true;
  }

  public isDisabled() {
    return !this.validateAarsagTilBrug();
  }

  public close(): boolean {
    if (this.validateAarsagTilBrug()) {
      this.globalWorkingService.show();
      this.brugerStore
        .brugerAarsagtilbrugPost(this.pensionCustomer?.aarsagTilBrug)
        .pipe(finalize(() => this.globalWorkingService.hide()))
        .subscribe(() => this.dialog.close());
      return true;
    }
    return false;
  }
  private validateAarsagTilBrug() {
    return Object.values(AarsagTilBrug).includes(
      this.pensionCustomer?.aarsagTilBrug
    );
  }
}
