import {
  AfterViewInit,
  Component,
  DestroyRef,
  inject,
  OnInit
} from '@angular/core';
import {
  ApiService,
  OpsparingPolice,
  PensionsKundeGenerelleData,
  SavingsService,
  TilpasForsikringstal,
  Type
} from 'ds-api';
import { combineLatest, fromEvent, take } from 'rxjs';
import { CookieService, InsuranceUtilService } from '@pfaform';
import { ActivatedRoute, Router } from '@angular/router';
import { OnboardingService } from './onboarding.service';
import { OnboardingTrackingService } from './onboarding-tracking.service';
import { RoutingService } from '@mitpfa/shared';
import {
  OnboardingGuideName,
  OnboardingLevel,
  OnboardingStepperLevel
} from './onboarding.model';
import { fadeInOut } from '@pfa/animations';
import { UntypedFormGroup } from '@angular/forms';
import { ExternalTransferService } from './external-transfer/external-transfer.service';
import {
  CombineSavingsNavigationEvent,
  CombineSavingsStep,
  CombineSavingsSummary,
  CombineSavingsSummaryType
} from '@mitpfa/shared/combine-savings/combine-savings.model';
import { InsuranceApiService } from '@pfa/api';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GuidePlacement, GuideStepType, GuideTrackingService } from '@pfa/core';

@Component({
  selector: 'mitpfa-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  animations: [fadeInOut]
})
export class OnboardingComponent implements AfterViewInit, OnInit {
  public pensionCustomer: PensionsKundeGenerelleData;
  public error: boolean;
  public levelReached: OnboardingLevel;
  public isFlowResumed = false;
  public footerForm: UntypedFormGroup;
  public isUpdateError: boolean;
  public currentCombineSavingsSummaryType: CombineSavingsSummary;
  private investmentStepperHack = 0;
  private insuranceStepperHack = 0;

  private readonly onboardingService: OnboardingService =
    inject(OnboardingService);
  private readonly onboardingTrackingService: OnboardingTrackingService =
    inject(OnboardingTrackingService);
  private readonly insuranceApiService: InsuranceApiService =
    inject(InsuranceApiService);
  private readonly insuranceUtilService: InsuranceUtilService =
    inject(InsuranceUtilService);
  private readonly savingsService: SavingsService = inject(SavingsService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly routingService: RoutingService = inject(RoutingService);
  private readonly cookieService: CookieService = inject(CookieService);
  private readonly guideTrackingService: GuideTrackingService =
    inject(GuideTrackingService);
  public combineSavingService: ExternalTransferService = inject(
    ExternalTransferService
  );
  public apiService: ApiService = inject(ApiService);
  public router: Router = inject(Router);
  public initialized: boolean;

  public ngOnInit(): void {
    // clear back links if user comes from logind
    if (this.routingService.getPreviousUrl().indexOf('logind') > -1) {
      history.pushState(null, '', location.href);
    }

    this.activatedRoute.data.subscribe(
      (data: { pensionCustomer: PensionsKundeGenerelleData }) => {
        this.pensionCustomer = data.pensionCustomer;
        if (this.pensionCustomer.kundeEmail && this.pensionCustomer.mobil) {
          this.onboardingService.showContactFooter();
        }
      }
    );

    this.onboardingService
      .getLevelReached()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(res => {
        this.levelReached = res;
        this.trackStep();
        window.scroll(0, 0);
      });

    combineLatest([
      this.savingsService.opsparingGet(),
      this.insuranceApiService.getInsuranceDetails(),
      this.onboardingService.getOnboardingStepsList()
    ])
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: res => {
          this.setupInvestmentStep(res[0].policer);
          this.setupInsuranceStep(res[1].tilpasForsikringstal);
          this.onboardingService.setupInsuranceAndInvestmentTracking();
          this.skipSteps();
          this.onboardingService.setupSteps();
          this.onboardingService.updateOnboardingStatusList(res[2]);
          this.onboardingService.updateProgressForStep(
            this.levelReached.stepperLevel,
            this.onboardingService.calculateProgress()
          );
          this.isFlowResumed = this.onboardingService.isOnboardingResumed();
          this.onboardingTrackingService.trackWelcome(this.isFlowResumed);
          this.initialized = true;
        },
        error: () => {
          this.error = true;
        }
      });

    if (!this.cookieService.getCookie('adaShown')) {
      this.cookieService.setCookieOnlyInSession('adaShown', 'true');
    }
  }

  ngAfterViewInit(): void {
    fromEvent(window, 'popstate').subscribe(() => {
      history.pushState(null, '', location.href);
    });
  }

  private setupInvestmentStep(policer: Array<OpsparingPolice>) {
    const hasSavings = policer.some(
      (police: OpsparingPolice) =>
        police.type !== Type.Gennemsnit && police.type !== Type.Forsikring
    );
    this.onboardingService.setShowInvestment(hasSavings);
  }

  private setupInsuranceStep(tilpasForsikringstal: TilpasForsikringstal): void {
    const isPolicyBlocked: boolean =
      this.insuranceUtilService.isPolicyBlocked(tilpasForsikringstal);
    this.onboardingService.setShowInsurance(!isPolicyBlocked);
    if (!this.onboardingService.getShowInvestment() && !isPolicyBlocked) {
      this.onboardingService.changeStepperForInvestmentStep();
    }
  }

  private skipSteps() {
    if (!this.onboardingService.getShowInvestment()) {
      this.onboardingService.skipInvestment();
    }
    if (!this.onboardingService.getShowInsurance()) {
      this.onboardingService.skipInsurance();
    }
    if (
      !this.onboardingService.getShowInvestment() &&
      !this.onboardingService.getShowInsurance()
    ) {
      this.onboardingService.skipInsuranceAndInvestment();
    }
  }

  public saveStep(): void {
    this.levelReached.isChecked = true;
    if (this.levelReached.introductionPage) {
      this.onboardingService.goToNextStep();
    } else {
      const onboardingStepItem =
        this.onboardingService.findOnboardingStepItem();
      if (onboardingStepItem) {
        this.onboardingService
          .saveOnboardingStep(onboardingStepItem)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: () => {
              this.onboardingService.goToNextStep();
            },
            error: () => {
              this.isUpdateError = true;
            }
          });
      }
    }
  }

  private trackStep(): void {
    this.onboardingTrackingService.trackFlow(
      this.levelReached,
      this.isFlowResumed,
      this.currentCombineSavingsSummaryType
    );
  }

  public goBack(): void {
    this.onboardingService.goToPreviousStep();
  }

  public setFooterForm(form: UntypedFormGroup): void {
    this.footerForm = form;
  }

  public combineSavingsNextStep(
    combineSavingsNavigationEvent: CombineSavingsNavigationEvent
  ): void {
    const transferredPoliciesUids =
      combineSavingsNavigationEvent?.transferredPoliciesUids ?? [];
    window.scroll(0, 0);
    this.levelReached.nextLevel = this.combineSavingService.findNextStep(
      this.levelReached.level,
      transferredPoliciesUids
    );
    if (
      this.levelReached.nextLevel === 'STEP_INVESTMENT' &&
      this.combineSavingService.isNonTransferable()
    ) {
      this.levelReached.stepperProgress = 75;
    }
    this.trackCompleteStep();

    if (combineSavingsNavigationEvent) {
      this.currentCombineSavingsSummaryType = {
        combineSavingsResult: this.findSummaryType(
          combineSavingsNavigationEvent
        ),
        transferredPoliciesUids: transferredPoliciesUids
      };
    }
    this.saveStep();
  }

  private trackCompleteStep(): void {
    if (
      this.levelReached.nextLevel === 'STEP_INVESTMENT' ||
      this.levelReached.nextLevel === 'STEP_END'
    ) {
      this.guideTrackingService.trackGuideComplete(
        OnboardingGuideName.REGISTRATION_GUIDE,
        'RG complete',
        GuideStepType.MANDATORY,
        undefined,
        GuidePlacement.ONBOARDING
      );
    }
  }

  private findSummaryType(
    combineSavingsNavigationEvent: CombineSavingsNavigationEvent
  ): CombineSavingsSummaryType {
    return this.combineSavingService.findCombineSavingsSummaryType(
      this.levelReached.level,
      combineSavingsNavigationEvent
    );
  }

  public jumpOver(): void {
    if (this.levelReached.isCombineSavings) {
      if (this.levelReached.level === 'EXTERNAL_TRANSFER_CONSENT') {
        this.combineSavingsNextStep({
          from: CombineSavingsStep.Consent,
          wontTransfer: true
        });
      } else {
        this.combineSavingsNextStep(undefined);
      }
    } else {
      this.saveStep();
    }
  }

  public investmentStepperUpdate(progress: number): void {
    // hack investmentguide is not 100% in this step, so we have to make room for investment recomendation cards
    if (progress > 60) {
      this.investmentStepperHack += 4;
    }
    this.onboardingService.updateProgressForStep(
      OnboardingStepperLevel.INVESTMENT,
      progress - this.investmentStepperHack
    );
  }

  public insuranceStepperUpdate(progress: number): void {
    // hack investmentguide is not 100% in this step, so we have to make room for investment recomendation cards
    if (progress > 60) {
      this.insuranceStepperHack += 4;
    }
    this.onboardingService.updateProgressForStep(
      OnboardingStepperLevel.INSURANCE,
      progress - this.insuranceStepperHack
    );
  }

  public showJumpOver(): void {
    this.levelReached.showJumpover = true;
  }

  public done(): void {
    if (this.onboardingService.isPensionsInfoUpdated) {
      this.apiService.invalidateAll();
    }
    this.router.navigate(['handlingscenter']);
  }
}
