import {
  animate,
  group,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  inject,
  DestroyRef
} from '@angular/core';
import { combineLatest, of, timer, Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import {
  DashboardSavingsReturnService,
  SavingsReturnCardData
} from './dashboard-savings-return.service';
import { DashboardMessageService } from '../../dashboard-message.service';
import { MiscTrackingService } from '@pfa/core';
import { SavingsreturnStore, KundekapitalStore } from 'ds-api';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const animationEnterDelay = 300;
const animationEaseInOutPeriod = 300;

const animations = [
  trigger('enterLeave', [
    transition(':enter', [
      group([
        style({
          opacity: 0
        }),
        animate(
          `${animationEaseInOutPeriod}ms ${animationEnterDelay}ms`,
          style({
            opacity: 1
          })
        )
      ])
    ]),
    transition(':leave', [
      animate(`${animationEaseInOutPeriod}ms`, style({ opacity: 0 }))
    ])
  ])
];

@Component({
  selector: 'mitpfa-dashboard-savings-return',
  templateUrl: './dashboard-savings-return.component.html',
  styleUrls: ['./dashboard-savings-return.component.scss'],
  animations,
  providers: [DashboardSavingsReturnService]
})
export class DashboardSavingsReturnComponent implements OnInit {
  private readonly service: DashboardSavingsReturnService = inject(
    DashboardSavingsReturnService
  );
  private readonly dashboardMessageService: DashboardMessageService = inject(
    DashboardMessageService
  );
  private readonly trackingService: MiscTrackingService =
    inject(MiscTrackingService);
  private readonly savingsreturnStore: SavingsreturnStore =
    inject(SavingsreturnStore);
  private readonly kundekapitalStore: KundekapitalStore =
    inject(KundekapitalStore);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  @Output() contentLoaded = new EventEmitter<void>();

  public showIndex$: Observable<number>;

  public payoutsData: boolean;
  public payoutsDataLoaded: boolean;
  public animate: boolean;
  public errorFetchingData: boolean;
  public animationInterval = 12;
  public policiesWithError: boolean;
  public allPoliciesWithError: boolean;
  public headingTexts = ['DL.FS03.C19', 'DL.FS03.C20'];
  public data: SavingsReturnCardData;

  private showIndex = 0;
  private numCards = 2;

  constructor() {
    this.errorFetchingData = false;
  }

  public ngOnInit(): void {
    this.dashboardMessageService
      .getPayoutsData()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(d => {
        this.payoutsData = d;
        this.payoutsDataLoaded = true;
      });

    combineLatest({
      returnsOverview: this.savingsreturnStore.afkastGet(),
      accumulatedReturns:
        this.savingsreturnStore.akkafkastAfkastfraordningensstartGet(),
      customerCapital: this.kundekapitalStore.kundekapitalGet()
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: response => {
          this.data = {
            customerCapital: this.service.parseCustomerCapital(
              response.customerCapital
            ),
            link: this.service.buildLink(response.returnsOverview),
            onlyInsurances: response.returnsOverview.kunForsikringer,
            returns: this.service.parseReturns(
              response.returnsOverview,
              response.accumulatedReturns
            )
          } as SavingsReturnCardData;

          if (
            this.data.returns.accumulatedReturns !==
              this.data.returns.returnsThisYear &&
            this.data.returns.showAccumulatedReturns
          ) {
            this.animateCards();
          } else {
            this.alwaysShowYearToDate();
          }

          this.policiesWithError =
            this.data.customerCapital.policiesWithError ||
            this.data.returns.policiesWithError;
          this.allPoliciesWithError =
            this.data.customerCapital.allPoliciesWithError ||
            this.data.returns.allPoliciesWithError;
          this.contentLoaded.emit();
        },
        error: () => {
          this.errorFetchingData = true;
          this.contentLoaded.emit();
        }
      });
  }

  public trackReturn(): void {
    this.trackingService.trackNavigationCard(
      'Savings section',
      'Returns YTD',
      '/mitafkast'
    );
  }

  private alwaysShowYearToDate() {
    this.animate = false;
    this.showIndex$ = of(0);
  }

  private animateCards() {
    this.animate = true;
    this.showIndex = -1;
    this.showIndex$ = timer(
      0,
      this.animationInterval * 1000 + animationEnterDelay
    ).pipe(
      tap(() => {
        this.showIndex =
          this.showIndex === this.numCards - 1 ? 0 : this.showIndex + 1;
      }),
      map(() => this.showIndex),
      share()
    );
  }

  getHeadingText(index: number): string {
    if (this.errorFetchingData || this.allPoliciesWithError) {
      return 'DL.FS03.C46';
    }
    return this.headingTexts[index];
  }

  public stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }
}
