import {
  Directive,
  ElementRef,
  HostListener,
  inject,
  Input,
  OnInit
} from '@angular/core';
import { MessagingService } from '../messaging.service';

@Directive({
  selector: '[coGrowIt]'
})
export class GrowItDirective implements OnInit {
  private element: ElementRef = inject(ElementRef);
  private service: MessagingService = inject(MessagingService);

  @Input() initialHeight: number;
  @Input() resizeOnBlur?: boolean;

  private orgHeight = 0;
  private heightMinusMenu = this.service.getHeightMinusMenu(window);

  ngOnInit() {
    this.update();
  }

  @HostListener('blur')
  onBlur() {
    if (this.resizeOnBlur) {
      this.onBlurHandler();
    }
  }

  @HostListener('input')
  onInput() {
    this.update();
  }

  @HostListener('focus')
  onFocus() {
    this.update();
  }

  update() {
    this.element.nativeElement.style.cssText = `
      height: ${this.initialHeight}px;
      overflow: hidden;
    `;
    const height = this.element.nativeElement.scrollHeight;
    if (this.orgHeight === 0) {
      this.orgHeight = height;
    }
    if (height > 0 && height < this.heightMinusMenu) {
      this.element.nativeElement.style.cssText = `
        height: ${height}px;
        overflow: 'hidden';
      `;
    } else if (height >= this.heightMinusMenu) {
      this.element.nativeElement.style.cssText = `
        height: ${this.heightMinusMenu - 10}px;
        overflow-y: auto;
      `;
    }
  }

  onBlurHandler() {
    this.element.nativeElement.style.cssText = `
      height: ${this.orgHeight}px;
      overflow-y: auto;
    `;
  }
}
