import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { PensionsKundeGenerelleData } from 'ds-api';
import moment from 'moment';
import { OnboardingService } from '../onboarding.service';

@Component({
  selector: 'mitpfa-ayr-pensionsinfo',
  templateUrl: './pensionsinfo.component.html',
  styleUrls: ['./pensionsinfo.component.scss']
})
export class PensionsinfoComponent implements OnInit {
  @Output() goTo = new EventEmitter();
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Input() isUpdateError: boolean;

  public goOnDisabled = true;

  private readonly onboardingService: OnboardingService =
    inject(OnboardingService);

  public ngOnInit(): void {
    if (this.pensionCustomer.harPensionsinfo) {
      const piDato = moment(
        this.pensionCustomer.pensionsinfoDato,
        'DD.MM.YYYY'
      );
      const tenDagsAgo = moment().subtract(10, 'days');
      this.goOnDisabled = piDato.isBefore(tenDagsAgo);
    }
  }

  public continue(): void {
    this.onboardingService.isPensionsInfoUpdated = true;
    this.goTo.emit();
  }

  public popupOpen(): void {
    this.goOnDisabled = false;
  }
}
