import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
  DestroyRef
} from '@angular/core';
import { ContentService, PopupTrackingService, TrackingName } from '@pfa/core';
import { combineLatest, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { DashboardMessageService } from '../dashboard-message.service';
import { NextBestActionService } from '@mitpfa/shared';
import {
  DaekningsDetaljer,
  DynamicAdvisingCalculation,
  DynamicAdvisingOffer,
  OpsparingOversigt,
  PensionsKundeGenerelleData,
  PensionskundeStore,
  SavingsStore,
  SystemadministrationStore
} from 'ds-api';
import { PayoutPlanService } from '@mitpfa/shared/services/payout-plan.service';
import { DynamicAdvisingFilterService } from '@mitpfa/shared/services/dynamic-advising-filter.service';
import { Router } from '@angular/router';
import {
  DynamicAdvisingOfferExtended,
  PensionCheckService
} from '../../pension-check/pension-check.service';
import { fadeInOut } from '@pfa/animations';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'mitpfa-dashboard-savings',
  templateUrl: './dashboard-savings.component.html',
  styleUrls: ['./dashboard-savings.component.scss'],
  animations: [fadeInOut]
})
export class DashboardSavingsComponent implements OnInit {
  private readonly nextBestActionService: NextBestActionService = inject(
    NextBestActionService
  );
  private readonly dashboardMessageService: DashboardMessageService = inject(
    DashboardMessageService
  );
  private readonly systemadministrationStore: SystemadministrationStore =
    inject(SystemadministrationStore);
  private readonly contentService: ContentService = inject(ContentService);
  private readonly pensionskundeStore: PensionskundeStore =
    inject(PensionskundeStore);
  private readonly payoutPlanService: PayoutPlanService =
    inject(PayoutPlanService);
  private readonly dynamicAdvisingFilterService: DynamicAdvisingFilterService =
    inject(DynamicAdvisingFilterService);
  private readonly router: Router = inject(Router);
  private readonly popupTrackingService: PopupTrackingService =
    inject(PopupTrackingService);
  private readonly pensionCheckService: PensionCheckService =
    inject(PensionCheckService);
  private readonly savingsStore: SavingsStore = inject(SavingsStore);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  @Output() contentLoaded = new EventEmitter<void>();
  @Input() public pensionCustomer: PensionsKundeGenerelleData;

  public sigmaFailed: boolean;
  public showPensionCheckCard = true;
  public pensionCheckStatusHigh: boolean;
  public pensionCheckStatusMedium: boolean;
  public pensionCheckStatusAllOk: boolean;
  public pensionCheckInitialized: boolean;
  public isAdvisor = true;
  public payoutsData: boolean;
  public coverages: DaekningsDetaljer[];
  public payoutsStarted: boolean;
  public locPayoutInProgress: boolean;
  public initialized: boolean;

  private pensionsKundeGenerelleData: PensionsKundeGenerelleData;
  private pensionInContentLoaded: boolean;
  private payoutsContentLoaded: boolean;
  private returnContentLoaded: boolean;
  private paymentsContentLoaded: boolean;
  private investmentContentLoaded: boolean;
  private readonly TRACKING_NAME = 'pensioncheck - see recommendations';
  private readonly TRACKING_NAME_ALL_OK = 'pensioncheck - all okay';

  constructor() {
    this.pensionInContentLoaded = false;
    this.payoutsContentLoaded = false;
    this.returnContentLoaded = false;
    this.paymentsContentLoaded = false;
    this.investmentContentLoaded = false;
  }

  public ngOnInit(): void {
    combineLatest({
      pensionCustomer: this.pensionskundeStore.pensionskundeGet(),
      pfaPlan: this.payoutPlanService.pfaplan(),
      savings: this.savingsStore.opsparingGet()
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(responses => {
        this.locPayoutInProgress = this.getLoCPayoutInProgress(
          responses.savings
        );
        this.coverages =
          this.payoutPlanService.coveragesWithPayoutsStartedIncludingCoveragesThatPayoutsAreEnded(
            responses.pfaPlan
          );
        this.pensionsKundeGenerelleData = responses.pensionCustomer;
        this.payoutsStarted =
          responses.pensionCustomer.alder >= 60 && this.coverages.length > 0;

        this.dashboardMessageService
          .getPayoutsData()
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(d => (this.payoutsData = d));
        this.initialized = true;
        this.contentService.languageState.subscribe(() => {
          if (!this.dashboardMessageService.getHidePensionCheckCard()) {
            this.setupPensionCheckCard();
          }
        });
      });
  }

  private getLoCPayoutInProgress(savingsOverview: OpsparingOversigt): boolean {
    return savingsOverview.policer.some(
      policy => policy.tilstand === 'TabAfErhvervsevne'
    );
  }

  private setupPensionCheckCard(): void {
    this.systemadministrationStore
      .systemadministrationHentkillswitchesadminGet()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(killSwitches => {
          this.isAdvisor = this.pensionsKundeGenerelleData.isRaadgiver;
          if (
            killSwitches.killswitchDetailsPensionCheck.open &&
            !this.pensionsKundeGenerelleData.isRaadgiver
          ) {
            this.nextBestActionService
              .getAllSortedNbaCards()
              .pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe({
                next: (
                  dynamicAdvisingCalculation: DynamicAdvisingCalculation
                ) => {
                  const offers: DynamicAdvisingOffer[] =
                    this.dynamicAdvisingFilterService.getAllOffers(
                      dynamicAdvisingCalculation.offerGroups
                    );

                  this.pensionCheckService.trackNbaOffersList(
                    TrackingName.FRONTPAGE,
                    dynamicAdvisingCalculation.nbaId,
                    offers
                  );

                  if (offers) {
                    const nbaCardsWithOnlyOneDataOffer: DynamicAdvisingOfferExtended[] =
                      this.nextBestActionService.ensureOnlyOneDataOffer(offers);
                    this.pensionCheckStatusHigh = this.hasRedCard(
                      nbaCardsWithOnlyOneDataOffer
                    );
                    this.pensionCheckStatusMedium = this.hasYellowCard(
                      nbaCardsWithOnlyOneDataOffer
                    );
                    if (
                      this.pensionCheckStatusHigh ||
                      this.pensionCheckStatusMedium
                    ) {
                      this.popupTrackingService.trackPopupViewed(
                        this.modifyTrackingName()
                      );
                      this.pensionCheckStatusAllOk = false;
                    } else {
                      this.popupTrackingService.trackPopupViewed(
                        this.TRACKING_NAME_ALL_OK
                      );
                      this.pensionCheckStatusAllOk = true;
                      timer(10000).subscribe(() => {
                        this.closePensionCheckCard();
                      });
                    }
                  }
                  this.pensionCheckInitialized = true;
                },
                error: () => {
                  this.sigmaFailed = true;
                  this.pensionCheckInitialized = true;
                }
              });
          }
        })
      )
      .subscribe({
        next: () => {},
        error: () => {}
      });
  }

  private hasRedCard(offers: DynamicAdvisingOfferExtended[]): boolean {
    return offers.some(
      (offer: DynamicAdvisingOfferExtended) => offer.offerSeverity === 3
    );
  }

  private hasYellowCard(offers: DynamicAdvisingOfferExtended[]): boolean {
    return offers.some(
      (offer: DynamicAdvisingOfferExtended) => offer.offerSeverity === 2
    );
  }

  public goToPensionCheck(): void {
    this.popupTrackingService.trackPopupCallToAction(this.modifyTrackingName());
    this.router.navigateByUrl('/handlingscenter');
  }

  public closePensionCheckCard(): void {
    if (this.showPensionCheckCard) {
      this.popupTrackingService.trackPopupClosed(
        this.pensionCheckStatusAllOk
          ? this.TRACKING_NAME_ALL_OK
          : this.modifyTrackingName()
      );
      this.dashboardMessageService.setHidePensionCheckCard();
      this.showPensionCheckCard = false;
    }
  }

  private modifyTrackingName(): string {
    let trackingName = this.TRACKING_NAME;
    if (this.pensionCheckStatusHigh) {
      trackingName = `${trackingName} (red)`;
    } else if (this.pensionCheckStatusMedium) {
      trackingName = `${trackingName} (yellow)`;
    }
    return trackingName;
  }

  public pensionInLoaded(): void {
    this.pensionInContentLoaded = true;

    if (
      !this.payoutsContentLoaded &&
      !this.returnContentLoaded &&
      !this.paymentsContentLoaded &&
      !this.investmentContentLoaded
    ) {
      this.contentLoaded.emit();
    }
  }

  public payoutsLoaded(): void {
    this.payoutsContentLoaded = true;

    if (
      !this.pensionInContentLoaded &&
      !this.returnContentLoaded &&
      !this.paymentsContentLoaded &&
      !this.investmentContentLoaded
    ) {
      this.contentLoaded.emit();
    }
  }

  public returnLoaded(): void {
    this.returnContentLoaded = true;

    if (
      !this.pensionInContentLoaded &&
      !this.payoutsContentLoaded &&
      !this.paymentsContentLoaded &&
      !this.investmentContentLoaded
    ) {
      this.contentLoaded.emit();
    }
  }

  public paymentsLoaded(): void {
    this.paymentsContentLoaded = true;

    if (
      !this.pensionInContentLoaded &&
      !this.payoutsContentLoaded &&
      !this.returnContentLoaded &&
      !this.investmentContentLoaded
    ) {
      this.contentLoaded.emit();
    }
  }

  public investmentLoaded(): void {
    this.investmentContentLoaded = true;

    if (
      !this.pensionInContentLoaded &&
      !this.payoutsContentLoaded &&
      !this.returnContentLoaded &&
      !this.paymentsContentLoaded
    ) {
      this.contentLoaded.emit();
    }
  }
}
