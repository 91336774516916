import { Component, OnInit, inject, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LegitimationStatus } from '../proof-of-identity/proof-of-identity.model';
import { LegitimationApiService } from '@pfa/api';
import { ProofOfIdentityService } from '../proof-of-identity/proof-of-identity.service';

@Component({
  selector: 'mitpfa-legitimation',
  template: ''
})
export class LegitimationComponent implements OnInit {
  private readonly legitimationApiService: LegitimationApiService = inject(
    LegitimationApiService
  );
  private readonly poiService: ProofOfIdentityService = inject(
    ProofOfIdentityService
  );
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.legitimationApiService
      .getLegitimation()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(legitimation => {
        if (
          legitimation &&
          legitimation.status !== LegitimationStatus.NOT_REQUIRED
        ) {
          this.poiService.openDialog(
            legitimation,
            'proof of identity - pep paragraph 53'
          );
        }
      });
  }
}
