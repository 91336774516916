import { Component, inject, Input, OnInit } from '@angular/core';
import { MessagingService } from './messaging.service';
import { ContentUtilService } from '@pfa/core';
import { NewMessageComponent } from './new-message/new-message.component';
import { slideInRight, slideOutRight } from '@pfa/animations';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { CorrespondenceListData } from 'ds-api';
import { PopupStyle } from '@pfa/models';

@Component({
  selector: 'co-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.scss'],
  animations: [slideInRight, slideOutRight]
})
export class MessagingComponent implements OnInit {
  @Input() channel: string;
  @Input() nonCustomer: boolean;
  @Input() isRaadgiver: boolean;
  @Input() showContactInputEnabled: boolean;

  threads: any;
  showingText: string;
  allThreads: CorrespondenceListData[];
  threadsLoaded: boolean;
  hasUrgent: boolean;
  hasHighImportance: boolean;
  unreadCount: number;
  showing: [number, number];
  toomany: boolean;
  getThreadsFailed: boolean;
  selectedThreadItemId: number;
  selectedThread: any;
  composeNewMessage: boolean;
  threadOpen: boolean;
  getThreadFailed: boolean;
  filterText: string;
  ofText: string;
  includeArchiveData = false;
  nonCustomerPopupShown: boolean;

  private readonly service: MessagingService = inject(MessagingService);
  private readonly contentUtil: ContentUtilService = inject(ContentUtilService);
  private readonly dialog: MatDialog = inject(MatDialog);

  ngOnInit() {
    this.service.setNonCustomer(this.nonCustomer);
    this.service.setChannel(this.channel);
    this.service.setShowContactInputEnabled(this.showContactInputEnabled);
    this.getThreads();
    this.ofText = this.contentUtil.getContent('DL.BE02.C1001');
  }

  private sliceThreads(showing: [number, number]) {
    const threadItems = this.allThreads.slice(showing[0], showing[1]);
    const threadCount = this.allThreads.length;
    const endNumber = showing[1] > threadCount ? threadCount : showing[1];
    this.showingText =
      showing[0] + 1 + ' - ' + endNumber + ' af ' + threadCount;
    return threadItems;
  }

  private getThreads(cb?: Function) {
    this.service.getThreads(this.includeArchiveData).subscribe({
      next: data => {
        if (!data.fejlet) {
          this.threadsLoaded = true;
          this.hasUrgent = data.hasUrgent;
          this.hasHighImportance = data.hasHighImportance;
          this.unreadCount = data.unreadCount ? data.unreadCount : 0;
          this.showing = [0, 50];
          this.allThreads = data.threads;

          if (this.nonCustomer && this.allThreads?.length === 0) {
            if (!this.nonCustomerPopupShown) {
              this.composeMessage();
            }
            this.nonCustomerPopupShown = true;
          }

          if (this.allThreads && this.allThreads.length > 50) {
            this.toomany = true;
            this.threads = this.sliceThreads(this.showing);
          } else {
            this.toomany = false;
            this.threads = this.allThreads;
          }
          if (cb) {
            cb();
          }
          this.getThreadsFailed = false;
          this.handleSelectedThread();
        } else {
          this.getThreadsFailed = true;
          this.threads = [];
        }
      },
      error: () => {
        this.getThreadsFailed = true;
        this.threads = [];
      }
    });
  }

  private handleSelectedThread(): void {
    if (this.selectedThreadItemId) {
      const thread = this.threads.find(
        element => element.id === this.selectedThreadItemId
      );
      if (thread) {
        thread.selected = true;
        if (
          thread.unread &&
          this.selectedThread.id === this.selectedThreadItemId
        ) {
          // selection is now unread
          this.selectedThread.unread = true;
          this.openThread(this.selectedThread, true); // true = refresh
        }
      }
    }
  }

  filterInput() {
    if (this.filterText && this.filterText.length > 3) {
      this.threads = this.allThreads.filter(thread => {
        const taskId = thread.taskId;
        return taskId && taskId.indexOf(this.filterText) > -1;
      });
    } else {
      this.threads = this.sliceThreads(this.showing);
    }
  }

  openThread(threadItem, refresh?) {
    if (this.isRaadgiver) {
      return;
    }
    this.composeNewMessage = false;
    if (threadItem.selected && !refresh) {
      this.closeThread();
      return;
    }
    // new thread selected clear selection if it's not a refresh
    if (!refresh) {
      this.clearSelection();
      delete this.selectedThread;
    }
    this.threadOpen = true;
    this.service.getThread(threadItem.id, threadItem.unread).subscribe(
      thread => {
        if (thread) {
          thread.noReply = threadItem.noReply;
          thread.urgent = threadItem.urgent;
          this.selectedThread = thread;
          threadItem.selected = true;
          this.selectedThreadItemId = threadItem.id;
          if (threadItem.unread) {
            this.unreadCount = this.unreadCount - 1;
            if (this.unreadCount < 0) {
              this.unreadCount = 0;
            }
          }

          threadItem.unread = false;
        } else {
          this.selectedThread = 'failed';
          this.getThreadFailed = true;
        }
      },
      () => {
        this.getThreadFailed = true;
        this.selectedThread = 'failed';
      }
    );
  }

  public closeThread() {
    this.clearSelection();
    this.threadOpen = false;
    delete this.selectedThread;
  }

  private clearSelection() {
    this.threads?.forEach(t => {
      t.selected = false;
    });
    this.selectedThreadItemId = -1;
  }

  public prev50() {
    if (this.showing[0] === 0) {
      return;
    }
    this.showing[0] = this.showing[0] - 50;
    this.showing[1] = this.showing[1] - 50;
    this.threads = this.sliceThreads(this.showing);
  }

  public next50() {
    if (this.showing[1] > this.allThreads.length) {
      return;
    }
    this.showing[0] = this.showing[0] + 50;
    this.showing[1] = this.showing[1] + 50;
    this.threads = this.sliceThreads(this.showing);
  }

  public selectIncludeArchiveData(selectedArchiveData: MatSelectChange) {
    this.includeArchiveData = selectedArchiveData.value;
    this.refresh();
  }

  public refresh() {
    this.filterText = '';
    this.closeThread();
    this.getThreads();
  }

  private openThreadFromId(id) {
    return () => {
      const thread = this.threads.find(element => element.id === id);
      if (!thread) {
        return;
      } else {
        this.openThread(thread);
      }
    };
  }

  public composeMessage() {
    if (this.isRaadgiver) {
      // get out of here
      return;
    }

    const popup = this.dialog.open(NewMessageComponent, {
      panelClass: PopupStyle.PopupMedium,
      disableClose: true
    });
    popup.afterClosed().subscribe(newThreadId => {
      this.getThreads(this.openThreadFromId(newThreadId));
    });
  }
}
