import { Component, OnInit, inject, DestroyRef } from '@angular/core';
import { ContentService, PageTrackingService } from '@pfa/core';
import {
  BrugerStore,
  CustomerOptions,
  IndividualcustomerprofileStore,
  NavnOgStatusData
} from 'ds-api';
import { combineLatest } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'mitpfa-advantage-housing',
  templateUrl: './advantage-housing.component.html',
  styleUrls: ['./advantage-housing.component.scss']
})
export class AdvantageHousingComponent implements OnInit {
  private readonly brugerStore: BrugerStore = inject(BrugerStore);
  private readonly pageTrackingService: PageTrackingService =
    inject(PageTrackingService);
  private readonly individualcustomerprofileStore: IndividualcustomerprofileStore =
    inject(IndividualcustomerprofileStore);
  private readonly contentService: ContentService = inject(ContentService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  public isAdvisor: boolean;
  public lbInsuranceLinkAllowed: boolean;
  public lbInsuranceLink: string;

  public ngOnInit(): void {
    combineLatest([
      this.brugerStore.brugerGet(),
      this.individualcustomerprofileStore.individualcustomerprofileCustomeroptionsGet()
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (responses: [NavnOgStatusData, CustomerOptions]) => {
          this.isAdvisor = responses[0].isRaadgiver;

          if (
            this.contentService.getContent()['DL.LB01.C01'] === 'lbf-on' &&
            responses[1].lbInsuranceAllowed
          ) {
            this.lbInsuranceLinkAllowed = true;
            this.setLbInsuranceLink();
          }

          this.pageTrackingService.trackContentLoaded();
        }
      });

    this.contentService.languageState
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.lbInsuranceLinkAllowed) {
          this.setLbInsuranceLink();
        }
      });
  }

  private setLbInsuranceLink(): void {
    this.lbInsuranceLink =
      this.contentService.getLanguage() === 'da'
        ? 'LE.allefordele'
        : 'LE.allefordele_en';
  }

  public trackSignUp(signUpAction: string): void {
    this.pageTrackingService.trackNavigation(
      'pfahousing',
      signUpAction,
      'findbolig.nu'
    );
  }
}
