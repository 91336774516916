<div *ngIf="error">
  <co-alert class="full-page alert-center">
    <h3 [coContent]="'DL.VK01.C125'"></h3>
    <p [coContent]="'DL.VK01.C126'"></p>
    <button
      (click)="investmentDone()"
      coContent="DL.VK01.C127"
      mat-raised-button
      class="button-secondary spacing-unit--over"
      data-test-id="investment-guide-failed-to-load-thus-skip-btn"
    ></button>
  </co-alert>
</div>
<mitpfa-investment-guide
  *ngIf="isInitialized && !error && !isRecommendation"
  [pensionCustomer]="pensionCustomer"
  [investmentGuide]="investmentGuide"
  [skipIntro]="true"
  [isOnboardingFlow]="true"
  [broker]="broker"
  [pensionInfoSavedValues]="pensionInfoSavedValues"
  [hideStepperOnAllSteps]="true"
  (guideEnd)="done()"
  (percentCompleted)="percentDone($event)"
>
</mitpfa-investment-guide>
<mitpfa-investment-recommendation
  *ngIf="policy && investmentGuide && isRecommendation && !this.onlyInsurance"
  [policy]="policy.police"
  [investmentGuideInfo]="investmentGuide"
  [pensionCustomer]="pensionCustomer"
  [inForceSelections]="pensionCustomer.inForceSelections"
  [basket]="basket"
  [isOnboardingFlow]="true"
  (exit)="investmentDone()"
></mitpfa-investment-recommendation>
<div *ngIf="isRecommendation && this.onlyInsurance" class="spacing-unit--under">
  <co-card class="card--inline align--center">
    <h4 coContent="DL.INV.C682"></h4>
    <div coContent="DL.INV.C683"></div>
    <button
      mat-raised-button
      class="mat-focus-indicator button-secondary mat-raised-button mat-button-bas spacing-unit--over"
      (click)="investmentDone()"
      [coContent]="'DL.VK01.C132'"
    ></button>
  </co-card>
</div>
