import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { NextBestActionService } from '@mitpfa/shared';
import { DynamicAdvisingOffer, DynamicAdvisingOfferGroup } from 'ds-api';
import { DynamicAdvisingFilterService } from '@mitpfa/shared/services/dynamic-advising-filter.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'mitpfa-pension-check-indicator',
  templateUrl: './pension-check-indicator.component.html',
  styleUrls: ['./pension-check-indicator.component.scss']
})
export class PensionCheckIndicationComponent implements OnInit {
  private readonly nextBestActionService: NextBestActionService = inject(
    NextBestActionService
  );
  private readonly dynamicAdvisingFilterService: DynamicAdvisingFilterService =
    inject(DynamicAdvisingFilterService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  public amount: number;

  ngOnInit(): void {
    this.nextBestActionService
      .getPensionCheck$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        (dynamicAdvisingOfferGroups: Array<DynamicAdvisingOfferGroup>) => {
          this.amount = 0;

          const allOffers: DynamicAdvisingOffer[] =
            this.dynamicAdvisingFilterService.getAllOffers(
              dynamicAdvisingOfferGroups
            );

          const onlyOneDataOffer =
            this.nextBestActionService.ensureOnlyOneDataOffer(allOffers);
          onlyOneDataOffer.forEach(sigma => {
            if (
              sigma.offerSeverity >= 2 &&
              sigma.offerId !== 'MERGE_POLICIES_MERGE' &&
              sigma.offerId !== 'MERGE_POLICIES_ALL_OK'
            ) {
              this.amount = this.amount + 1;
            }
          });
        }
      );
  }
}
